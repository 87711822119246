import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize, tint, shade } from 'polished';
import {
  getPrimaryColor,
  getWhiteColor,
  getGreenColor,
  getRedColor,
  getDarkBlueColor,
  getDefaultRadius,
} from '../../theme';

import { Loader } from '../utils';
import Icons from '../icons';

//TODO : maybe these utilies can be put elsewhere. We'll see later
const handleColor = props => {
  if (props.secondary) {
    return handleColorByType(props);
  }
  if (props.outline) {
    if (props.white) {
      getWhiteColor(props);
    } else {
      return tint(1 - 0.75, getDarkBlueColor(props));
    }
  }
  if (props.clear) {
    if (props.primary) {
      return getPrimaryColor(props);
    } else if (props.white) {
      getWhiteColor(props);
    } else {
      return tint(1 - 0.75, getDarkBlueColor(props));
    }
  }
  return getWhiteColor(props);
};

const handleColorByType = props => {
  if (props.danger) {
    return getRedColor(props);
  }
  if (props.success) {
    return getGreenColor(props);
  }
  return getPrimaryColor(props);
};

const handleBackgroundColor = props => {
  if (props.clear) {
    return 'transparent';
  }
  if (props.outline) {
    if (props.white) {
      return 'transparent';
    } else {
      return getWhiteColor(props);
    }
  }
  if (props.secondary) {
    return tint(0.95, handleColorByType(props));
  }
  return handleColorByType(props);
};

const handleHoverBackgroundColor = props => {
  if (props.clear) {
    return 'transparent';
  }
  if (props.outline) {
    if (props.white) {
      return 'transparent';
    } else {
      return getWhiteColor(props);
    }
  }
  if (props.secondary) {
    return tint(0.95, handleColorByType(props));
  }
  return shade(1 - 0.96, handleColorByType(props));
};

const handleBorderColor = props => {
  if (props.outline) {
    if (props.white) {
      return getWhiteColor(props);
    } else {
      return tint(1 - 0.15, getDarkBlueColor(props));
    }
  }
  if (props.secondary) {
    return tint(0.75, handleColorByType(props));
  }
  return handleColorByType(props);
};

const handleShadowColor = props => {
  if (props.clear) {
    return 'none';
  }
  if (props.outline) {
    if (props.white) {
      return 'none';
    } else {
      return transparentize(1 - 0.08, getDarkBlueColor(props));
    }
  }
  if (props.secondary) {
    return transparentize(1 - 0.15, handleColorByType(props));
  }
  return transparentize(1 - 0.2, handleColorByType(props));
};

const handleSize = props => {
  if (props.size === 'large') {
    return css`
      font-size: 13px;
      padding: ${props => (props.iconOnly ? '4px 11px' : '4px 24px')};
      min-width: ${props =>
        props.iconOnly ? '0' : props.smallContent ? '48px' : '9rem'};
      min-height: 48px;
      line-height: 18px;

      svg {
        width: ${props => (!props.iconOnly ? '20px' : '24px')};
        height: ${props => (!props.iconOnly ? '20px' : '24px')};
      }
    `;
  }
  if (props.size === 'small') {
    return css`
      font-size: 12px;
      padding: ${props => (props.iconOnly ? '2px 7px' : '2px 12px')};
      min-width: ${props =>
        props.iconOnly ? '0' : props.smallContent ? '32px' : '9rem'};
      min-height: 32px;
      line-height: 14px;

      svg {
        width: ${props => (!props.iconOnly ? '14px' : '16px')};
        height: ${props => (!props.iconOnly ? '14px' : '16px')};
      }
    `;
  }
  if (props.size === 'xsmall') {
    return css`
      font-size: 12px;
      padding: ${props => (props.iconOnly ? '2px 4px' : '2px 12px')};
      min-width: ${props =>
        props.iconOnly ? '0' : props.smallContent ? '24px' : '5rem'};
      min-height: 24px;
      line-height: 14px;

      svg {
        width: ${props => (!props.iconOnly ? '12px' : '14px')};
        height: ${props => (!props.iconOnly ? '12px' : '14px')};
      }
    `;
  } else {
    return css`
      font-size: 13px;
      padding: ${props => (props.iconOnly ? '4px 9px' : '4px 16px')};
      min-width: ${props =>
        props.iconOnly ? '0' : props.smallContent ? '40px' : '9rem'};
      min-height: ${props => (props.clear ? 0 : '40px')};
      line-height: 18px;

      svg {
        width: ${props => (!props.iconOnly ? '16px' : '20px')};
        height: ${props => (!props.iconOnly ? '16px' : '20px')};
      }
    `;
  }
};

const handlePadding = props => {
  if (props.size === 'large') {
    return css`
      padding: ${props =>
        props.clear ? '0' : props.iconOnly ? '4px 11px' : '4px 24px'};
    `;
  }
  if (props.size === 'small') {
    return css`
      padding: ${props =>
        props.clear ? '0' : props.iconOnly ? '2px 7px' : '2px 12px'};
    `;
  }
  if (props.size === 'xsmall') {
    return css`
      padding: ${props =>
        props.clear ? '0' : props.iconOnly ? '2px 4px' : '2px 12px'};
    `;
  } else {
    return css`
      padding: ${props =>
        props.clear ? '0' : props.iconOnly ? '4px 9px' : '4px 16px'};
    `;
  }
};

const handleMargin = props => {
  if (props.m) {
    return css`
      margin: ${props => props.theme.spacing[props.m]};
    `;
  } else {
    return css`
      margin-left: ${props =>
        props.mh
          ? props.theme.spacing[props.mh]
          : props.ml
          ? props.theme.spacing[props.ml]
          : 0};
      margin-right: ${props =>
        props.mh
          ? props.theme.spacing[props.mh]
          : props.mr
          ? props.theme.spacing[props.mr]
          : 0};
      margin-top: ${props =>
        props.mv
          ? props.theme.spacing[props.mv]
          : props.mt
          ? props.theme.spacing[props.mt]
          : 0};
      margin-bottom: ${props =>
        props.mv
          ? props.theme.spacing[props.mv]
          : props.mb
          ? props.theme.spacing[props.mb]
          : 0};
    `;
  }
};

const handleAlign = props => {
  if (props.align === 'left') {
    return css`
      text-align: left;
    `;
  }
  if (props.align === 'center') {
    return css`
      text-align: center;
    `;
  }
  if (props.align === 'right') {
    return css`
      text-align: right;
    `;
  }
};

const handleDisabled = props => {
  if (props.disabled && !props.isLoading) {
    return css`
      opacity: 0.5;
    `;
  }
  if (props.disabled && props.isLoading) {
    return css`
      opacity: 1;
    `;
  }
};

const StyledButton = styled('button')`
  display: ${props => (props.as ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  width: ${props => (props.fluid ? `100%` : 'auto')};
  background-color: ${handleBackgroundColor};
  border: ${props => (!props.clear ? '1px solid' : 'none')};
  border-color: ${handleBorderColor};
  border-radius: ${getDefaultRadius};
  box-shadow: 0 0 0 ${handleShadowColor};
  color: ${handleColor};
  font-weight: 500;
  transition: all 0.25s ease;
  ${handleSize};
  ${handlePadding};
  ${handleMargin};
  ${handleAlign};
  ${handleDisabled};

  &:hover,
  &:focus {
    cursor: pointer;

    ${props =>
      !props.disabled
        ? css`
            box-shadow: 0 2px 4px ${handleShadowColor};
            background-color: ${handleHoverBackgroundColor};
          `
        : css`
            cursor: not-allowed;
          `}
  }
  &:active {
    box-shadow: none;
  }
  &:focus {
    outline: 0;
  }

  svg {
    flex-shrink: 0;
    display: inline-block;

    ${props =>
      props.iconOnly
        ? css`
            margin: 0;
          `
        : props.iconRight
        ? css`
            margin-left: 8px;
          `
        : css`
            margin-right: 8px;
          `}
  }

  span {
    flex-grow: 1;
    ${({ underline }) =>
      underline
        ? css`
            text-decoration: underline;
          `
        : null}
  }
`;

const Button = props => {
  const { icon, iconRight, children } = props;
  if (icon) {
    if (typeof icon === 'string') {
      return (
        <StyledButton {...props} iconOnly={!children}>
          {props.isLoading ? (
            <Loader
              size='xsmall'
              tint={props.outline ? 0.5 : 1}
              color={
                props.primary
                  ? props.secondary
                    ? 'primary'
                    : 'white'
                  : props.danger
                  ? props.secondary
                    ? 'red'
                    : 'white'
                  : props.outline || props.clear
                  ? 'darkBlue'
                  : 'white'
              }
            />
          ) : (
            <>
              {!iconRight ? Icons[icon]({}) : null}
              {children && <span>{children}</span>}
              {iconRight ? Icons[icon]({}) : null}
            </>
          )}
        </StyledButton>
      );
    } else {
      return (
        <StyledButton {...props} iconOnly={!children}>
          {props.isLoading ? (
            <Loader
              size='xsmall'
              tint={props.outline ? 0.5 : 1}
              color={
                props.primary
                  ? props.secondary
                    ? 'primary'
                    : 'white'
                  : props.danger
                  ? props.secondary
                    ? 'red'
                    : 'white'
                  : props.outline || props.clear
                  ? 'darkBlue'
                  : 'white'
              }
            />
          ) : (
            <>
              {!iconRight ? icon : null}
              {children}
              {iconRight ? icon : null}
            </>
          )}
        </StyledButton>
      );
    }
  } else {
    return (
      <StyledButton {...props}>
        {props.isLoading ? (
          <Loader
            size='xsmall'
            tint={props.outline ? 0.5 : 1}
            color={
              props.primary
                ? props.secondary
                  ? 'primary'
                  : 'white'
                : props.danger
                ? props.secondary
                  ? 'red'
                  : 'white'
                : props.outline || props.clear
                ? 'darkBlue'
                : 'white'
            }
          />
        ) : (
          <span>{children}</span>
        )}
      </StyledButton>
    );
  }
};

Button.propTypes = {
  fluid: PropTypes.bool,
  size: PropTypes.string,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  danger: PropTypes.bool,
  outline: PropTypes.bool,
  white: PropTypes.bool,
  clear: PropTypes.bool,
  align: PropTypes.string,
  isLoading: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  iconRight: PropTypes.bool,
  type: PropTypes.string,
  smallContent: PropTypes.bool,
  underline: PropTypes.bool,
  m: PropTypes.string,
  mh: PropTypes.string,
  mv: PropTypes.string,
  mt: PropTypes.string,
  mr: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
};

Button.defaultProps = {
  fluid: false,
  size: 'medium',
  clear: false,
  white: false,
  align: 'center',
  isLoading: false,
  icon: null,
  iconRight: false,
  type: 'button',
  smallContent: false,
  underline: false,
  m: null,
  mh: null,
  mv: null,
  mt: null,
  mr: null,
  mb: null,
  ml: null,
};

export default Button;
