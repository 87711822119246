import React from 'react';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { Wrapper } from '../utils';
import { Text } from '../typography';
import Icons from '../icons';

const Link = props => {
  const { icon, color, url, onClick, disabled, children } = props;

  if(url) {
    return (
      <LinkToElement
        href={url}
        rel='noopener noreferrer'
        target='_blank'
      >
        { icon && (
          <Wrapper dFlex alignItems='center' mr='smaller'>
            <Icon {...props} />
          </Wrapper>
        )}
        <Text color={color} opacity={disabled ? 0.5 : 1}>{children}</Text>
      </LinkToElement>
    )
  } else {
    return (
      <LinkElement
        dFlex
        alignItems='center'
        mv='smaller'
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
      >
        { icon && (
          <Wrapper dFlex alignItems='center' mr='smaller'>
            <Icon {...props} />
          </Wrapper>
        )}
        <Text color={color} opacity={disabled ? 0.5 : 1}>{children}</Text>
      </LinkElement>
    )
  }
}

const LinkToElement = styled.a`
  display: flex;
  align-items: center;
  margin-top: ${({theme}) => theme.spacing.smaller};
  margin-bottom: ${({theme}) => theme.spacing.smaller};
  
  &:hover {
    cursor: pointer;
  }
`;

const LinkElement = styled(props => <Wrapper {...props} />)`
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled(props => Icons[props.icon]({...props}))`
  width: 1.6rem;
  height: 1.6rem;
  stroke: ${({theme, color, disabled}) => transparentize(disabled ? 0.75 : 0.5, theme.colors[color])};
  transition: all 0.25s ease-in-out;
  
  ${LinkToElement}:hover & {
    stroke: ${({theme, color}) => theme.colors[color]};
  }
  
  ${LinkElement}:hover & {
    stroke: ${({theme, color}) => theme.colors[color]};
  }
`;

export default Link;