import React from 'react';

const SvgPCustomers = props => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      opacity={0.25}
      d='M27 26v-1.625c0-2.687-2.583-4.913-5.942-5.307'
      stroke='#EE3657'
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19 26v-1.625C19 21.411 15.86 19 12 19s-7 2.411-7 5.375V26'
      stroke='#EE3657'
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity={0.25}
      d='M19.245 14.923c.246.042.496.077.755.077 2.48 0 4.5-2.019 4.5-4.5S22.48 6 20 6c-.26 0-.51.034-.756.077'
      stroke='#EE3657'
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 15a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z'
      stroke='#EE3657'
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SvgPCustomers;
