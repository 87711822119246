import React from 'react';

const PItemTemplate = props => (
  <svg
    width={32}
    height={32}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6 6h13.875a3 3 0 013 3v14.085a5 5 0 00.782 2.685l.146.23H10a7 7 0 01-7-7V9a3 3 0 013-3z'
      stroke='#624DE3'
      strokeWidth={2}
    />
    <path
      d='M23.125 23.325v-5.408h3.514A2.361 2.361 0 0129 20.277V22.8a3.2 3.2 0 01-3.2 3.2 2.675 2.675 0 01-2.675-2.675z'
      fill='#624DE3'
      fillOpacity={0.5}
      stroke='#624DE3'
      strokeWidth={2}
    />
    <rect
      x={8}
      y={19}
      width={8}
      height={2}
      rx={1}
      fill='#624DE3'
      fillOpacity={0.5}
    />
    <rect
      x={8}
      y={15}
      width={3}
      height={2}
      rx={1}
      fill='#624DE3'
      fillOpacity={0.5}
    />
    <rect
      x={12}
      y={15}
      width={6}
      height={2}
      rx={1}
      fill='#624DE3'
      fillOpacity={0.5}
    />
    <rect x={8} y={11} width={6} height={2} rx={1} fill='#624DE3' />
    <rect x={15} y={11} width={3} height={2} rx={1} fill='#624DE3' />
  </svg>
);

export default PItemTemplate;
