import httpService from './http-service';

const BASE_URL = '/customer';

class CustomerService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  getCustomers({
    limit = 20,
    archived = false,
    skip = 0,
    search,
    projectData = false,
  } = {}) {
    return httpService.get(this._generateRoute(''), {
      params: {
        limit,
        archived,
        skip,
        search,
        projectData,
      },
    });
  }

  getCustomersCount({ archived = false, search } = {}) {
    return httpService.get(this._generateRoute('count'), {
      params: {
        archived,
        search,
      },
    });
  }

  getCustomerById(customerId) {
    return httpService.get(this._generateRoute(customerId));
  }

  createCustomer({
    status,
    name,
    address,
    email,
    phone,
    identificationNumber,
    VATNumber,
  }) {
    return httpService.post(this._generateRoute('create'), {
      status,
      name,
      address,
      email,
      phone,
      identificationNumber,
      VATNumber,
    });
  }
  createCustomerBulk(customers) {
    return httpService.post(this._generateRoute('create-bulk'), customers);
  }

  updateCustomer(
    customerId,
    { status, name, address, email, phone, identificationNumber, VATNumber }
  ) {
    return httpService.put(this._generateRoute(customerId), {
      status,
      name,
      address,
      email,
      phone,
      identificationNumber,
      VATNumber,
    });
  }

  archiveCustomer(customerId) {
    return httpService.put(this._generateRoute(`${customerId}/archive`));
  }

  unarchiveCustomer(customerId) {
    return httpService.put(this._generateRoute(`${customerId}/unarchive`));
  }

  deleteCustomer(customerId) {
    return httpService.delete(this._generateRoute(customerId));
  }
}

export const customerService = new CustomerService();
