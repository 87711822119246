import moment from 'moment';
import { USER_TYPE, LIMIT_TYPE } from '../../utils/constants/user.constants';

class UserBusinessService {
  /**
   * User is confirmed
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  isConfirmed(user) {
    return user.confirmed;
  }

  /**
   * User is regular
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  isRegular(user) {
    return user.userType === USER_TYPE.REGULAR;
  }

  // PAID STATUS

  /**
   * User is free
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  isFree(user) {
    return user.paidStatus === USER_TYPE.FREE;
  }

  /**
   * User is free
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  isTrial(user) {
    return user.paidStatus === USER_TYPE.TRIAL;
  }

  /**
   * User is payment_issues
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  isPaymentIssues(user) {
    return user.paidStatus === USER_TYPE.PAYMENT_ISSUES;
  }

  /**
   * User is paid
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  isPaid(user) {
    return user.paidStatus === USER_TYPE.PAID;
  }

  /**
   * User can have trial
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  canHaveTrial(user) {
    return user.canHaveTrial;
  }

  /**
   * Get user email
   *
   * @param { Object } user
   *
   * @return { String }
   */
  getUserEmail(user) {
    return user.email;
  }

  /**
   * Get Trial end date
   *
   * @param { Number } trialEnd
   *
   * @return { Number }
   */
  getTrialEnd(trialEnd) {
    const endAt = moment.unix(trialEnd);
    const today = moment();
    return endAt.diff(today, 'days');
  }

  /**
   * Get limit
   *
   * @param { Object } limits
   * @param { String } type
   *
   * @return { Number }
   */
  getLimit(limits, type) {
    if (type === 'revenue') {
      return limits.revenueLimit;
    } else if (type === 'customer') {
      return limits.customerLimit;
    } else {
      return null;
    }
  }

  /**
   * Get current limit value
   *
   * @param { Object } limits
   * @param { String } type
   *
   * @return { Number }
   */
  getCurrentLimit(limits, type) {
    if (type === LIMIT_TYPE.REVENUE) {
      return limits.currentRevenue;
    } else if (type === LIMIT_TYPE.CUSTOMER) {
      return limits.currentCustomerNumber;
    } else {
      return null;
    }
  }

  /**
   * Customer limit reached
   *
   * @param { Object } limits
   *
   * @return { Boolean }
   */
  isCustomerLimitReached(limits) {
    if (limits.customerLimit > 0) {
      return limits.currentCustomerNumber >= limits.customerLimit;
    } else {
      return false;
    }
  }

  /**
   * Revenue limit reached
   *
   * @param { Object } limits
   *
   * @return { Boolean }
   */
  isRevenueLimitReached(limits) {
    if (limits.revenueLimit > 0) {
      return limits.currentRevenue >= limits.revenueLimit;
    } else {
      return false;
    }
  }

  /**
   * Get years from user's company creation date
   *
   * @param { Object } user
   *
   * @return { Array }
   */
  getYearsFromCreationDate(user) {
    let years = [];
    const { creationDate } = user.settings.company;
    const currentYear = moment().month() === 11 ? moment().year() + 1 : moment().year()
    for (let i = currentYear; i >= moment(creationDate).year(); i--) {
      years.push(i);
    }
    return years;
  }

  /**
   * User has no estimate settings
   *
   * @param { Object } user
   *
   * @return { Boolean }
   */
  hasNoEstimateSettings(user) {
    return (
      !user.settings.estimates ||
      (user.settings.estimates &&
        (user.settings.estimates.prefix === '' ||
          user.settings.estimates.prefix === null))
    );
  }
}

export const userBusinessService = new UserBusinessService();
