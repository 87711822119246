import React from 'react';

const SvgPCard = props => (
  <svg width={32} height={32} fill='none'>
    <path
      d='M7 7H25C26.6569 7 28 8.34315 28 10V16V22C28 23.6569 26.6569 25 25 25H7C5.34315 25 4 23.6569 4 22V10C4 8.34315 5.34315 7 7 7Z'
      stroke='#624DE3'
      strokeWidth='2'
    />
    <rect x='8' y='19' width='16' height='2' rx='1' fill='#D7D2F8' />
    <rect x='8' y='12' width='5' height='4' rx='1' fill='#B1A6F1' />
  </svg>
);

export default SvgPCard;
