import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize, tint } from 'polished';

// Size rules
const handleSize = props => {
  if (props.type === 'h1') {
    return css`
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 3.2rem;
    `;
  }
  if (props.type === 'h2') {
    return css`
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.8rem;
    `;
  }
  if (props.type === 'h3') {
    return css`
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2.4rem;
    `;
  }
  if (props.type === 'h4') {
    return css`
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2.4rem;
    `;
  }
  if (props.type === 'h5') {
    return css`
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2rem;
    `;
  }
};

// Color rules
const handleColor = props => {
  if (props.tint) {
    return css`
      color: ${props.color
        ? tint(1 - props.tint, props.theme.colors[props.color])
        : tint(1 - props.tint, props.theme.colors.darkBlue)};
    `;
  } else if (props.opacity) {
    return css`
      color: ${props.color
        ? transparentize(1 - props.opacity, props.theme.colors[props.color])
        : transparentize(1 - props.opacity, props.theme.colors.darkBlue)};
    `;
  } else {
    return css`
      color: ${props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.darkBlue};
    `;
  }
};

// Styled component
const HeadingItem = styled.div`
  ${handleSize};
  ${handleColor};
  transition: all 0.25s ease-in-out;

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `}
`;

// Component
const Heading = props => {
  const { type, children } = props;

  return (
    <HeadingItem as={`${type}`} {...props}>
      {children}
    </HeadingItem>
  );
};

Heading.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  tint: PropTypes.number,
  opacity: PropTypes.number,
  align: PropTypes.string,
  underline: PropTypes.bool,
};

Heading.defaultProps = {
  type: 'h1',
  color: null,
  tint: null,
  opacity: null,
  align: 'left',
  underline: false,
};

export default Heading;
