import React from 'react';
import { rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Wrapper } from '../utils';
import { Text } from '../typography';

const SideNav = props => {
  const { soon, ...otherProps } = props;
  return (
    <SideNavElement {...otherProps}>
      <SideNavWrapper
        dFlex
        justifyContent='space-between'
        alignItems='center'
        pv='smaller'
        ph='small'
        r='regular'
      >
        <SideNavText weight='medium' tint={props.disabled ? 0.5 : null}>
          {props.children}
        </SideNavText>
        {props.soon && (
          <Wrapper
            ph='xsmall'
            flex='0 0 auto'
            color='primary'
            tint={0.1}
            r='small'
          >
            <Text
              size='xxxsmall'
              weight='medium'
              uppercase
              color='primary'
              tint={1}
            >
              Soon
            </Text>
          </Wrapper>
        )}
        {props.tag && !props.soon && (
          <Wrapper
            ph='smaller'
            pv='xxsmall'
            ml={'xsmall'}
            flex='0 0 auto'
            color='primary'
            r='large'
          >
            <Text size='xsmall' weight='semi' color='white' tint={1}>
              {props.tag}
            </Text>
          </Wrapper>
        )}
      </SideNavWrapper>
    </SideNavElement>
  );
};

export default SideNav;

const SideNavElement = styled(NavLink)`
  display: block;
  flex: 0 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing.smaller};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const SideNavWrapper = styled(props => <Wrapper {...props} />)`
  transition: all 0.25s ease-in-out;

  ${SideNavElement}:hover & {
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  }

  ${SideNavElement}.active & {
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  }
`;

const SideNavText = styled(Text)`
  ${SideNavElement}:hover & {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${SideNavElement}.active & {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
