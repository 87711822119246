import React from 'react';

const SvgPlan = props => (
  <svg width={32} height={32} fill='none'>
    <path
      d='M7 4H18C19.6569 4 21 5.34315 21 7V11H14C12.3431 11 11 12.3431 11 14V21H7C5.34315 21 4 19.6569 4 18V7C4 5.34315 5.34315 4 7 4Z'
      fill='#D7D2F8'
      stroke='#624DE3'
      strokeWidth='2'
    />
    <path
      d='M14 11H25C26.6569 11 28 12.3431 28 14V19.5V25C28 26.6569 26.6569 28 25 28H14C12.3431 28 11 26.6569 11 25V14C11 12.3431 12.3431 11 14 11Z'
      stroke='#624DE3'
      strokeWidth='2'
    />
    <rect x='15' y='15' width='9' height='3' rx='1.5' fill='#D7D2F8' />
  </svg>
);

export default SvgPlan;
