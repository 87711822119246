import { generateMedia } from 'styled-media-query';
import DefaultTheme from './DefaultTheme';

const breakpoints = DefaultTheme.flexboxgrid.breakpoints;

//To get same breakpoints as react-styled-flexboxgrid
//Breakpoints are in em
let customBreakpoints = {};
for (let mediaKey in breakpoints) {
  customBreakpoints[mediaKey] = `${breakpoints[mediaKey]}em`;
}

const customMedia = generateMedia(customBreakpoints);

export default customMedia;
