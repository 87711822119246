import React from 'react';

const Google = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M12 4.75C13.77 4.75 15.355 5.36 16.605 6.55L20.03 3.125C17.95 1.19 15.235 0 12 0C7.31003 0 3.25503 2.69 1.28003 6.61L5.27003 9.705C6.21503 6.86 8.87003 4.75 12 4.75Z'
        fill='#EA4335'
      />
      <path
        d='M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275V12.275Z'
        fill='#4285F4'
      />
      <path
        d='M5.265 14.295C5.025 13.57 4.885 12.8 4.885 12C4.885 11.2 5.02 10.43 5.265 9.70499L1.275 6.60999C0.46 8.22999 0 10.06 0 12C0 13.94 0.46 15.77 1.28 17.39L5.265 14.295Z'
        fill='#FBBC05'
      />
      <path
        d='M12 24C15.24 24 17.965 22.935 19.945 21.095L16.08 18.095C15.005 18.82 13.62 19.245 12 19.245C8.86996 19.245 6.21496 17.135 5.26496 14.29L1.27496 17.385C3.25496 21.31 7.30996 24 12 24Z'
        fill='#34A853'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Google;
