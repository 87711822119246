import React from 'react';

const SvgXOctagon = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='x-octagon_svg__feather x-octagon_svg__feather-x-octagon'
    {...props}
  >
    <path d='M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2zM15 9l-6 6M9 9l6 6' />
  </svg>
);

export default SvgXOctagon;
