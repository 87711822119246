import React from 'react';
import styled, { css, keyframes, withTheme } from 'styled-components';
import { transparentize } from 'polished';
import ReactModal from 'react-modal';

import { mediaQueries } from '../../theme';
import { Heading } from '../typography';
import { Button } from '../button';
import { Wrapper } from '../utils';

import Icons from '../icons';

ReactModal.setAppElement('#root');

// Animation style
// const OverlayAppear = keyframes`
//   from { opacity: 0; }
//   to { opacity: 1; }
// `;

const ModalAppear = keyframes`
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
  }
`;

const handleSize = props => {
  if (props.size === 'medium') {
    return css`
      max-width: 480px;
    `;
  } else if (props.size === 'large') {
    return css`
      max-width: 640px;
    `;
  } else {
    return css`
      max-width: 480px;
    `;
  }
};

const StyledModal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 0;

  animation-name: ${ModalAppear};
  animation-duration: 0.35s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  padding-left: 16px;
  padding-right: 16px;

  width: 100%;
  ${handleSize};
  max-height: 90%;
  overflow: visible;
  margin: auto;

  ${mediaQueries.lessThan('md')`
    animation: none;
  `}
`;

const ModalWrapper = styled(Wrapper)`
  width: 100%;
  overflow: auto;
`;

const ModalHeader = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalHeaderTitle = styled(Wrapper)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalHeaderTitleIcon = styled(Wrapper)`
  flex-shrink: 0;
`;
const ModalHeaderTitleText = styled(Wrapper)`
  flex: 1;
`;

const ModalElement = props => {
  const { children, style, icon, closeButton, ...otherProps } = props;
  return (
    <StyledModal
      {...otherProps}
      style={{
        ...style,
        overlay: {
          ...(style && style.overlay),
          display: 'flex',
          zIndex: 1000,
          backgroundColor: transparentize(
            1 - 0.85,
            props.theme.colors.darkBlue
          ),
          // animation: `${OverlayAppear} 5s ease-out`,
        },
      }}
    >
      <ModalWrapper r='large' color='white' flex='auto'>
        <ModalHeader ph='large' pv='medium'>
          <ModalHeaderTitle mr='regular'>
            {icon && (
              <ModalHeaderTitleIcon mr='regular'>
                {Icons[icon]({})}
              </ModalHeaderTitleIcon>
            )}
            <ModalHeaderTitleText>
              <Heading type='h3'>{props.contentLabel}</Heading>
            </ModalHeaderTitleText>
          </ModalHeaderTitle>
          {closeButton && (
            <Button
              clear
              size='large'
              icon='X'
              onClick={props.onRequestClose}
            />
          )}
        </ModalHeader>
        {children}
      </ModalWrapper>
    </StyledModal>
  );
};

export default withTheme(ModalElement);
