import _ from 'underscore';
import { customerService } from './customer-service';
import { invoiceService } from './invoice-service';
import { projectService } from './project-service';
import { documentBusinessService } from './business';

class ImportService {
  //Return array of ids customer created
  _createCustomers(customersNames) {
    const customers = customersNames.map(name => {
      const customer = {
        status: 'company',
        name: name,
        address: '',
        email: '',
        phone: '',
        identificationNumber: '',
        VATNumber: '',
      };
      return customer;
    });
    return customerService.createCustomerBulk(customers);
  }

  //Return array of ids projects created
  _createProjects(projectsNamesWithCustomersId) {
    const projects = projectsNamesWithCustomersId.map(
      ({ name, customerId }) => {
        const project = {
          customerId: customerId,
          name: name,
        };
        return project;
      }
    );
    return projectService.createProjectsBulk(projects);
  }

  // Create invoices
  _createInvoices(invoicesWithProjectId) {
    const invoices = invoicesWithProjectId.map(i => {
      const invoice = {
        ...i,
        legalNotice: '',
        customerInformation: {
          name: '',
          address: '',
          email: '',
          phone: '',
          identificationNumber: '',
          VATNumber: '',
        },
        companyInformation: {
          identificationNumber: '',
          address: '',
          zipCode: '',
          city: '',
          VATNumber: '',
          firstName: '',
          lastName: '',
          email: '',
          logoUrl: '',
        },
        createdAt: i.sentAt,
        dueAt: i.sentAt,
        discount: 0,
      };
      return invoice;
    });
    return invoiceService.createInvoicesBulk(invoices);
  }

  // Parse data
  _parseData(data) {
    const p = _.chain(data)
      .map(line => {
        // line data
        const {
          customerName,
          projectName,
          invoiceName,
          invoiceSubject,
          invoiceAmountWithoutTax,
          invoiceVAT,
          invoiceSentAt,
          invoicePaidAt,
          invoicePaidWith,
        } = line;

        return {
          customerName,
          projectName,
          invoice: documentBusinessService.createExternalInvoiceFrom({
            name: invoiceName,
            projectId: null,
            subject: invoiceSubject,
            amountWithoutTax: invoiceAmountWithoutTax,
            VAT: invoiceVAT,
            sentAt: invoiceSentAt,
            paidAt: invoicePaidAt,
            paidWith: invoicePaidWith,
          }),
        };
      })
      .groupBy(lineObject => {
        const customerName = lineObject.customerName;
        return customerName;
      })
      .mapObject((value, customerName) => {
        return _.chain(value)
          .groupBy(lineObject => {
            const projectName = lineObject.projectName;
            return projectName;
          })
          .value();
      })
      .mapObject((projects, customerName) => {
        return {
          name: customerName,
          projects: _.chain(projects)
            .mapObject((invoices, projectName) => {
              return {
                name: projectName,
                invoices: invoices.map(d => d.invoice),
              };
            })
            .toArray()
            .value(),
        };
      })
      .toArray()
      .value();
    // return data;
    return p;
  }

  // Create Datas
  // createDatas(data) {
  //   const dataFormated = this._parseData(data);
  //   const customerNames = dataFormated.map(c => c.name);
  //   const nestedInvoices = dataFormated.map(c =>
  //     c.projects.map(p => p.invoices)
  //   );
  //   const futureCustomersIds = this._createCustomers(customerNames);
  //   const futureNestedProjects = futureCustomersIds.then(customersIds => {
  //     return customersIds.data.map((customerId, index) => {
  //       return dataFormated[index].projects.map(p => ({
  //         name: p.name,
  //         customerId,
  //       }));
  //     });
  //   });

  //   return futureNestedProjects.then(nestedProjects => {
  //     const invoices = nestedProjects.map((c, cIndex) => {
  //       return c.flatMap((pId, pIndex) => {
  //         const invoicesToCreate = nestedInvoices[cIndex][pIndex];
  //         //Do something here
  //         return invoicesToCreate.map(i => {
  //           return {
  //             ...i,
  //             projectId: pId,
  //           };
  //         });
  //       });
  //     });
  //     return this._createInvoices(invoices);
  //   });
  // }

  createDatas(data) {
    const dataFormated = this._parseData(data);
    const customerNames = dataFormated.map(c => c.name);
    const nestedInvoices = dataFormated.map(c =>
      c.projects.map(p => p.invoices)
    );

    return this._createCustomers(customerNames)
      .then(customersIds => {
        const projects = customersIds.data
          .map((customerId, index) => {
            return dataFormated[index].projects.map(p => ({
              name: p.name,
              customerId,
            }));
          })
          .flat();

        const projectsWithInvoices = customersIds.data.map(
          (customerId, cIndex) => {
            return dataFormated[cIndex].projects.map((p, pIndex) => ({
              name: p.name,
              customerId,
              invoices: nestedInvoices[cIndex][pIndex],
            }));
          }
        );

        return this._createProjects(projects)
          .then(projectsIds => {
            const invoices = projectsWithInvoices
              .flat()
              .map((p, pIndex) => {
                return p.invoices.map(i => {
                  return {
                    ...i,
                    projectId: projectsIds.data[pIndex],
                  };
                });
              })
              .flat();

            return this._createInvoices(invoices)
              .then(res => {
                return res;
              })
              .catch(err => {
                return err;
              });
          })
          .catch(err => {
            return err;
          });
      })
      .catch(err => {
        return err;
      });
  }
}

export const importService = new ImportService();
