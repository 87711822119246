class ReferralBusinessService {
  generateReferralLink(referrerCode) {
    return `${process.env.REACT_APP_REFERRAL_PREFIX_URL}${referrerCode}`;
  }

  //
  // Generate Link to share for Referral
  //
  generateShareReferralLink(medium, referrerCode) {
    const url = this.generateReferralLink(referrerCode);
    const encodedURL = encodeURIComponent(url);
    const text = `Micro-entrepreneurs, gérez facilement vos devis, factures, plafonds, cotisations ! Je vous offre 5 euros pour tester @Pakko_app en suivant ce lien :`;

    if (medium === 'facebook') {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;
    } else if (medium === 'twitter') {
      return `https://twitter.com/intent/tweet?url=${encodedURL}&text=${text}`;
    } else if (medium === 'linkedin') {
      return `http://www.linkedin.com/shareArticle?mini=true&url=${encodedURL}&title=${text}`;
    } else {
      return null;
    }
  }

  getReferredStatus(referred) {
    if (referred.state === 'signed_up') {
      return 'Inscrit.e';
    } else if (referred.state === 'trialing') {
      return 'Essai';
    } else if (referred.state === 'active') {
      return 'Att. 1er paiement';
    } else if (referred.state === 'paid') {
      return `Validé +${referred.amountSaved / 100} €`;
    } else {
      return 'Inconnu';
    }
  }

  getReferredStatusColor(referred) {
    if (referred.state === 'signed_up') {
      return 'darkBlue';
    } else if (referred.state === 'trialing') {
      return 'yellow';
    } else if (referred.state === 'active') {
      return 'yellow';
    } else if (referred.state === 'paid') {
      return `green`;
    } else {
      return 'darkBlue';
    }
  }
}

export const referralBusinessService = new ReferralBusinessService();
