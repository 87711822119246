import moment from 'moment';

class DeadlineBusinessService {
  isRevenueDeclaration(deadline) {
    return deadline.slug.startsWith('revenueTax');
  }

  isVATDownPayment(deadline) {
    return deadline.slug.startsWith('VAT');
  }

  isLandContribution(deadline) {
    return deadline.slug.startsWith('landcontribution');
  }

  isLate(deadline) {
    return deadline.endAt < moment().valueOf();
  }

  getDeadlineColor(deadline) {
    if (this.isRevenueDeclaration(deadline)) {
      return 'primary';
    } else if (this.isVATDownPayment(deadline)) {
      return 'green';
    } else if (this.isLandContribution(deadline)) {
      return 'blue';
    } else {
      return 'primary';
    }
  }

  getDeadlineIcon(deadline) {
    if (this.isRevenueDeclaration(deadline)) {
      return 'icon_graph';
    } else if (this.isVATDownPayment(deadline)) {
      return 'icon_donut';
    } else if (this.isLandContribution(deadline)) {
      return 'icon_money';
    } else {
      return '';
    }
  }

  getDeadlineDate(deadline) {
    const date = moment(deadline.endAt).format('D MMMM YYYY');
    if (this.isLate(deadline)) {
      return `Le ${date}`;
    } else {
      return `Avant le ${date}`;
    }
  }

  getDeadlineLink(deadline) {
    if (this.isRevenueDeclaration(deadline)) {
      return `https://www.autoentrepreneur.urssaf.fr/`;
    } else if (this.isVATDownPayment(deadline)) {
      return `https://www.impots.gouv.fr/`;
    } else if (this.isLandContribution(deadline)) {
      return `https://www.impots.gouv.fr/`;
    } else {
      return null;
    }
  }

  getDescription(deadline) {
    if (this.isRevenueDeclaration(deadline)) {
      return `Déclaration chiffre d'affaires ${this.getRevenueTaxPeriod(
        deadline
      )} `;
    } else if (this.isVATDownPayment(deadline)) {
      return `Déclaration TVA - ${this.getVATPeriod(deadline)}`;
    } else if (this.isLandContribution(deadline)) {
      return `Paiement Cotisation Foncière des Entreprises (CFE)`;
    } else {
      return `Échéance`;
    }
  }

  /*
   * REVENUE TAX
   */

  getRevenueTaxPeriod(deadline) {
    const { periodicity, year, period } = this.getRevenueTaxPeriodData(
      deadline
    );

    if (periodicity === 'quarter') {
      const suffix = period === 0 ? 'er' : 'ème';
      return `du ${period + 1}${suffix} trimestre ${year}`;
    } else {
      const month = moment()
        .month(period)
        .format('MMMM');
      if (period === 3 || period === 7 || period === 9) {
        return `d'${month} ${year}`;
      } else {
        return `de ${month} ${year}`;
      }
    }
  }

  getRevenueTaxPeriodData(deadline) {
    const slugData = deadline.slug.split('_');

    return {
      periodicity: slugData[1],
      year: slugData[2],
      period: parseInt(slugData[3]),
    };
  }

  getRevenuesToDeclare(data, periodicity, period, year) {
    const yearData = data[year];
    if (yearData) {
      const yearRevenues = this._generateRevenuesYear(yearData);

      if (periodicity === 'month') {
        const monthRevenues = yearRevenues[period];
        return parseInt(monthRevenues.toFixed(0));
      } else if (periodicity === 'quarter') {
        const refQuarter = period * 3;
        const quarterRevenues = yearRevenues
          .slice(refQuarter, refQuarter + 3)
          .reduce((acc, monthValue) => {
            return acc + monthValue;
          }, 0);
        return parseInt(quarterRevenues.toFixed(0));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  _generateRevenuesYear(data) {
    const months = Array(12)
      .fill(0)
      .map((i, index) => index);

    return months.map(month => {
      const dataForMonth = data[month];
      if (dataForMonth) {
        return dataForMonth.totalWithoutTax;
      } else {
        return 0;
      }
    });
  }

  /*
   * VAT
   */
  getVATPeriod(deadline) {
    const { year, period } = this.getVATPeriodData(deadline);

    if (period === 1) {
      return `Acompte juillet ${year}`;
    } else if (period === 2) {
      return `Acompte décembre ${year}`;
    } else if (period === 3) {
      return `${year}`;
    }
  }

  getVATPeriodData(deadline) {
    const slugData = deadline.slug.split('_');

    return {
      year: slugData[1],
      period: parseInt(slugData[2]),
    };
  }
}

export const deadlineBusinessService = new DeadlineBusinessService();
