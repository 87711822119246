
class CustomerBusinessService {
  hasNoProject(activeCount, archivedCount) {
    if(activeCount.count === null || archivedCount === null) {
      return false;
    } else {
      return activeCount.count + archivedCount.count === 0;
    }
  }
}

export const customerBusinessService = new CustomerBusinessService();
