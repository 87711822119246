import React from 'react';

const PInfo = props => (
  <svg width={32} height={32} fill='none' {...props}>
    <circle cx={17} cy={16} r={12} fill='#DBDAE0' />
    <circle
      cx={15}
      cy={16}
      r={11}
      fill='#fff'
      stroke='#878497'
      strokeWidth={2}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.125 17.862c.4-.1 3.7-1.3 3.7-3.9 0-1.7-1.1-3.2-2.7-3.7-2.1-.8-4.4.3-5.1 2.4-.1.5.1 1.1.6 1.1.5.2 1.1-.1 1.3-.6.4-1 1.6-1.6 2.6-1.2.8.3 1.3 1 1.3 1.9 0 1.1-1.7 1.9-2.3 2.1-.5.2-.8.8-.6 1.3.1.4.5.7.9.7.1 0 .2 0 .3-.1zm.3 2.3c.1 0 .2.1.2.1.1.1.2.2.1.3.1.1.1.3.1.4 0 .3-.1.5-.3.7-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.117.034-.2.063-.269.02-.048.037-.09.037-.131 0-.1.1-.2.2-.3.1-.1.2-.2.3-.2.2-.1.4-.1.6-.1.057.057.082.082.111.092.022.008.046.008.089.008.05 0 .075.025.1.05.025.025.05.05.1.05z'
      fill='#878497'
    />
  </svg>
);

export default PInfo;
