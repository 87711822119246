import React from 'react';
import styled, { keyframes, withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { transparentize } from 'polished';
import ReactModal from 'react-modal';

import { mediaQueries } from '../../theme';
import { Heading } from '../typography';
import { Button } from '../button';
import { Wrapper } from '../utils';

import Icons from '../icons';

ReactModal.setAppElement('#root');

const ModalAppear = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
  }
`;

const StyledModal = styled(ReactModal)`
  position: absolute;
  width: 50%;
  max-width: 60rem;
  height: 100%;
  right: 0;
  top: 0;
  outline: 0;
  background: #ffffff;
  box-shadow: -0.4rem 0 6.8rem rgba(0, 0, 0, 0.05);

  animation-name: ${ModalAppear};
  animation-duration: 0.35s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  ${mediaQueries.lessThan('md')`
    animation: none;
    width: 75%;
  `}

  ${mediaQueries.lessThan('sm')`
    width: 100%;
  `}
`;

const ModalWrapper = styled(Wrapper)`
  width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled(Wrapper)`
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};

  ${mediaQueries.greaterThan('sm')`
    padding-left: ${({ theme }) => theme.spacing.large};
    padding-right: ${({ theme }) => theme.spacing.large};
  `}
`;

const ModalContent = styled(Wrapper)`
  overflow: hidden;
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.2rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  ${mediaQueries.greaterThan('sm')`
    padding-left: ${({ theme }) => theme.spacing.large};
    padding-right: ${({ theme }) => theme.spacing.large};
  `}
`;

const ModalContentWrapper = styled(Wrapper)`
  overflow: auto;
`;

const BackButton = styled(Wrapper)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.darkBlue};
  opacity: 0.75;

  svg {
    transition: all 0.25s ease-in-out;
  }
  &:hover {
    svg {
      transform: translateX(-3px);
    }
  }
`;

const ModalRightElement = props => {
  const { t } = useTranslation(['commons']);
  const {
    children,
    style,
    icon,
    closeButton,
    hasPrev,
    onBack,
    ...otherProps
  } = props;
  return (
    <StyledModal
      {...otherProps}
      style={{
        ...style,
        overlay: {
          ...(style && style.overlay),
          display: 'flex',
          zIndex: 1000,
          backgroundColor: transparentize(0.95, props.theme.colors.darkBlue),
        },
      }}
    >
      <ModalWrapper
        dFlex
        flexDirection={'column'}
        alignItems={'stretch'}
        color='white'
      >
        {/* Header */}
        <HeaderWrapper
          flex={'0 0 auto'}
          dFlex
          justifyContent={'space-between'}
          alignItems={'center'}
          pt='medium'
        >
          {/* Title */}
          {hasPrev ? (
            <Wrapper flex={'1'} dFlex alignItems={'center'} mr='regular'>
              <BackButton
                clickable
                flexShrink={'0'}
                mr='regular'
                onClick={onBack}
              >
                {Icons['ArrowLeft']({})}
              </BackButton>
              <Wrapper flex={'1'}>
                <Heading type='h3'>{t('commons:back')}</Heading>
              </Wrapper>
            </Wrapper>
          ) : (
            <Wrapper flex={'1'} dFlex alignItems={'center'} mr='regular'>
              {icon && (
                <Wrapper flexShrink={'0'} mr='regular'>
                  {Icons[icon]({})}
                </Wrapper>
              )}
              <Wrapper flex={'1'}>
                <Heading type='h3'>{props.contentLabel}</Heading>
              </Wrapper>
            </Wrapper>
          )}
          {closeButton && (
            <Button
              clear
              size='large'
              icon='X'
              onClick={props.onRequestClose}
            />
          )}
        </HeaderWrapper>
        {/* Content */}
        <ModalContent
          flex={'1'}
          dFlex
          flexDirection={'column'}
          alignItems={'stretch'}
        >
          <ModalContentWrapper flex={'0 0 100%'} pt={'large'} pb={'huge'}>
            {children}
          </ModalContentWrapper>
        </ModalContent>
      </ModalWrapper>
    </StyledModal>
  );
};

export default withTheme(ModalRightElement);
