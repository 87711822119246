import httpService from './http-service';

const BASE_URL = '/data';

class DataService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  getInvoiceCardDateForYear(year) {
    return httpService.get(this._generateRoute('invoice-card'), {
      params: {
        year,
      },
    });
  }
  getDashboardForYear(year) {
    return httpService.get(this._generateRoute('dashboard'), {
      params: {
        year,
      },
    });
  }

  getCustomerDataForYear(year, customerId) {
    return httpService.get(this._generateRoute(`customer-card/${customerId}`), {
      params: {
        year,
      },
    });
  }
}

export const dataService = new DataService();
