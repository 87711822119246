import { storageService } from './storage-service';
import jwt_decode from 'jwt-decode';
import httpService from './http-service';

const BASE_URL = '/account';

class UserService {
  constructor() {
    this._isRefreshingTokens = false;
    this._refreshTokenPromise = null;
  }

  _storeTokens(token, refreshToken) {
    storageService.storeAccessToken(token);
    storageService.storeRefreshToken(refreshToken);
  }

  _storeIsAdmin() {
    storageService.storeIsAdmin('true');
  }

  _getTokens() {
    const tk = storageService.getAccessToken();
    const rtk = storageService.getRefreshToken();
    return {
      token: tk,
      refreshToken: rtk,
    };
  }

  _getIsAdmin() {
    storageService.getIsAdmin();
  }

  _clearTokens() {
    storageService.clearTokens();
  }

  _clearAllStorage() {
    storageService.clearAllStorage();
  }

  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  _handleLoginResponse({ token, refreshToken }) {
    this._storeTokens(token, refreshToken);
    return {
      token,
      refreshToken,
    };
  }

  getDecodedToken() {
    const token = storageService.getAccessToken();

    if (token) {
      let decodedToken;
      try {
        decodedToken = jwt_decode(token);
      } catch (err) {
        decodedToken = null;
      }
      return decodedToken;
    } else {
      return null;
    }
  }

  isLogged() {
    return this.getDecodedToken() ? true : false;
  }

  isOnboarded() {
    const token = this.getDecodedToken();
    if (token) {
      return token.onboarded ? true : false;
    } else {
      return false;
    }
  }

  login(email, password) {
    return httpService
      .post(this._generateRoute('login'), {
        email: email,
        password: password,
      })
      .then(res => this._handleLoginResponse(res.data));
  }

  loginWithGoogle(tokenId) {
    return httpService
      .post(this._generateRoute('login-with-google'), {
        tokenId: tokenId,
      })
      .then(res => this._handleLoginResponse(res.data));
  }

  logout() {
    return httpService
      .post(this._generateRoute('logout'))
      .then(res => {
        return res;
      })
      .finally(() => this._clearTokens());
  }

  create({ firstName, lastName, email, password, coupon }) {
    return httpService
      .post(this._generateRoute('create'), {
        firstName,
        lastName,
        email,
        password,
        coupon,
      })
      .then(res => this._handleLoginResponse(res.data));
  }

  createWithGoogle({ tokenId, coupon }) {
    return httpService
      .post(this._generateRoute('create-with-google'), {
        tokenId,
        coupon,
      })
      .then(res => this._handleLoginResponse(res.data));
  }

  //
  // Ask reset Password
  //
  askResetPassword(email) {
    return httpService.post(`${this._generateRoute('reset-password')}`, {
      email: email,
    });
  }
  // -- Confirm reset with new password
  resetPassword(resetKey, newPassword) {
    return httpService.put(
      `${this._generateRoute('reset-password')}/${resetKey}`,
      {
        password: newPassword,
      }
    );
  }

  //
  // Update password in settings
  //
  updatePassword(password, newPassword) {
    return httpService.put(this._generateRoute('change-password'), {
      password,
      newPassword,
    });
  }

  //
  // Ask resend new confirm email
  //
  resendConfirmEmail(email) {
    return httpService.post(this._generateRoute('resend-confirm-email'), {
      email,
    });
  }

  //
  // POST - Change email
  //
  changeEmail(newEmail) {
    return httpService.post(this._generateRoute('change-email'), {
      newEmail,
    });
  }

  //
  //  Confirm change email + set password if Google User
  //
  confirmNewEmail(confirmKey, newPassword) {
    return httpService.put(this._generateRoute('change-email'), {
      confirmKey,
      newPassword,
    });
  }

  //
  // Confirm account after creation
  //
  confirmEmail(key) {
    return httpService
      .put(`${this._generateRoute('confirm-email')}/${key}`)
      .then(res => this._handleLoginResponse(res.data));
  }

  refreshToken() {
    if (this._isRefreshingTokens) {
      return this._refreshTokenPromise;
    }
    this._isRefreshingTokens = true;
    const refreshToken = storageService.getRefreshToken();
    const url = `${this._generateRoute('refresh-token')}/${refreshToken}`;
    this._refreshTokenPromise = httpService
      .put(url)
      .then(res => {
        const tokens = this._handleLoginResponse(res.data);
        this._isRefreshingTokens = false;
        return tokens;
      })
      .catch(() => {
        this._clearTokens();
        document.location.reload();
      });
    return this._refreshTokenPromise;
  }

  //
  // Update user infos
  //
  updateUserInfos(userInfos) {
    return httpService.put(this._generateRoute('user-info'), userInfos);
  }

  //
  // Get settings
  //
  getSettings() {
    return httpService.get(this._generateRoute('settings'));
  }

  //
  // Update settings + refresh token
  //
  async updateSettings(settings) {
    const res = await httpService.put(
      this._generateRoute('settings'),
      settings
    );
    await this.refreshToken();
    return res;
  }

  //
  // Get user limits
  //
  getLimits() {
    return httpService.get(this._generateRoute('limits'));
  }

  //
  // LOGO
  //
  uploadLogo(file) {
    const formData = new FormData();
    formData.append('logo', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return httpService.post(`${this._generateRoute('logo')}`, formData, config);
  }

  deleteLogo() {
    return httpService.delete(`${this._generateRoute('logo')}`);
  }

  //
  // Delete
  //
  deleteData() {
    return httpService.delete(this._generateRoute('data'));
  }

  deleteAccount() {
    return httpService.delete(this._generateRoute('')).then(res => {
      return res;
    });
  }
}

export const userService = new UserService();
