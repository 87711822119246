import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { tint, transparentize } from 'polished';

import { Wrapper, Separator } from '../utils';
import { Text } from '../typography';
import { Checkbox } from '../inputs';
import Icons from '../icons';

const handlePosition = props => {
  const { top, right, bottom, left } = props;

  if (!top && !right && !bottom && !left) {
    return css`
      left: 0;
      top: calc(100% + 8px);
    `;
  } else {
    return css`
      ${top && 'bottom : calc(100% + 8px)'};
      ${right && 'right : 0'};
      ${bottom && 'top : calc(100% + 8px)'};
      ${left && 'left : 0'};
    `;
  }
};

// Component
const Dropdown = props => {
  const { children, items, disabled, ...otherProps } = props;

  const [showMenu, setShowMenu] = useState(false);

  return (
    <DropdownWrapper>
      {/* OVERLAY */}
      {showMenu && (
        <Overlay
          onClick={e => {
            e.preventDefault();
            setShowMenu(false);
          }}
          active={showMenu}
        />
      )}

      {/* TRIGGER */}
      <DropdownTrigger
        onClick={e => {
          e.preventDefault();
          if (!disabled) {
            setShowMenu(!showMenu);
          }
        }}
      >
        {children}
      </DropdownTrigger>

      {/* MENU */}
      {showMenu && (
        <DropdownMenu color='white' mb='smaller' r='regular' {...otherProps}>
          <Wrapper p='smaller'>
            {items.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.type === 'separator' && (
                    <Wrapper mb='xsmall'>
                      <Separator />
                    </Wrapper>
                  )}
                  {(!item.type || item.type === 'button') && (
                    <DropdownItem
                      color='white'
                      danger={item.danger}
                      r='small'
                      mb='xsmall'
                      onClick={() => {
                        if (typeof item.onClick === 'function' && !disabled) {
                          item.onClick();
                        }
                        setShowMenu(false);
                      }}
                    >
                      {/* is a link */}
                      {item.linkTo && (
                        <DropdownItemLink to={item.linkTo}>
                          <Wrapper dFlex alignItems='center' p='smaller'>
                            {item.icon ? (
                              <DropdownItemIcon
                                mr='smaller'
                                danger={item.danger}
                              >
                                {Icons[item.icon]({})}
                              </DropdownItemIcon>
                            ) : null}
                            <DropdownItemText danger={item.danger}>
                              {item.label}
                            </DropdownItemText>
                          </Wrapper>
                        </DropdownItemLink>
                      )}
                      {/* is an url */}
                      {item.url && (
                        <DropdownItemUrl
                          href={item.url}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <Wrapper dFlex alignItems='center' p='smaller'>
                            {item.icon ? (
                              <DropdownItemIcon
                                mr='smaller'
                                danger={item.danger}
                              >
                                {Icons[item.icon]({})}
                              </DropdownItemIcon>
                            ) : null}
                            <DropdownItemText danger={item.danger}>
                              {item.label}
                            </DropdownItemText>
                          </Wrapper>
                        </DropdownItemUrl>
                      )}
                      {/* is not a link */}
                      {!item.linkTo && !item.url ? (
                        <Wrapper flex='1' dFlex alignItems='center' p='smaller'>
                          {item.icon ? (
                            <DropdownItemIcon mr='smaller' danger={item.danger}>
                              {Icons[item.icon]({})}
                            </DropdownItemIcon>
                          ) : null}
                          <DropdownItemText danger={item.danger}>
                            {item.label}
                          </DropdownItemText>
                        </Wrapper>
                      ) : null}
                    </DropdownItem>
                  )}
                  {item.type === 'toggle' && (
                    <DropdownToggleItem
                      dFlex
                      alignItems={'center'}
                      mb='xsmall'
                      p='smaller'
                    >
                      <Wrapper flex={'0 0 auto'} mr={'smaller'}>
                        <Checkbox
                          type={'checkbox'}
                          id={'english-labels'}
                          name={'english-labels'}
                          label={item.label}
                          switch
                          checked={item.value === 'en'}
                          onChange={e => {
                            if (
                              typeof item.onClick === 'function' &&
                              !disabled
                            ) {
                              item.onClick(e);
                            }
                          }}
                        />
                      </Wrapper>
                    </DropdownToggleItem>
                  )}
                </React.Fragment>
              );
            })}
          </Wrapper>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      danger: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      url: PropTypes.string,
      linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
  disabled: PropTypes.bool,
  top: PropTypes.bool,
  left: PropTypes.bool,
  bottom: PropTypes.bool,
  right: PropTypes.bool,
  size: PropTypes.number,
};

Dropdown.defaultProps = {
  disabled: false,
  top: false,
  left: false,
  right: false,
  bottom: false,
  size: null,
};

export default Dropdown;

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 2;
  display: ${props => (props.active ? 'block' : 'none')};
`;

const MenuAppear = keyframes`
  from { 
    opacity: 0;
    transform: translateY(-8px);
  }
  to { 
    opacity: 1;
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownTrigger = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const DropdownMenu = styled(Wrapper)`
  position: absolute;
  width: 100%;
  min-width: ${({ size }) => (size ? size + 'px' : '16rem')};
  ${handlePosition};
  border: 1px solid ${({ theme }) => tint(1 - 0.1, theme.colors.darkBlue)};
  box-shadow: 0 0.2rem 0.4rem
    ${({ theme }) => transparentize(1 - 0.05, theme.colors.darkBlue)};
  animation: ${MenuAppear} 0.35s ease-out;
  z-index: 3;
  overflow: auto;
  max-height: 280px;
`;

const DropdownItemIcon = styled(Wrapper)`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    stroke: ${({ danger, theme }) =>
      !!danger ? theme.colors.red : theme.colors.primary};
  }
`;

const DropdownItemText = styled(Text)`
  flex: 1;
`;

const DropdownToggleItem = styled(Wrapper)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const DropdownItem = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ danger, theme }) =>
      !!danger
        ? tint(1 - 0.05, theme.colors.red)
        : tint(1 - 0.05, theme.colors.primary)};

    ${DropdownItemIcon} {
      svg {
        stroke: ${({ danger, theme }) =>
          !!danger ? theme.colors.red : theme.colors.primary};
      }
    }

    ${DropdownItemText} {
      color: ${({ danger, theme }) =>
        !!danger ? theme.colors.red : theme.colors.primary};
    }
  }
`;

const DropdownItemLink = styled(Link)`
  display: block;
  flex: 1;
`;

const DropdownItemUrl = styled.a`
  display: block;
  flex: 1;
`;
