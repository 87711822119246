import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Async from 'react-select/async';
import styled, { withTheme } from 'styled-components';
import { tint, transparentize } from 'polished';
import debounce from 'debounce-promise';

import { Text } from '../typography';
import Icons from '../icons';

// Styled component
const InputGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  color: ${props => props.theme.colors.darkBlue};
`;

// Icon
const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.6rem;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

const InputLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`;

// Label
const Label = styled(Text)`
  flex-shrink: 0;
  flex-grow: 1;
  transition: all 0.25s ease-in-out;
`;

// Error
const Error = styled(Text)`
  margin-top: 0.4rem;
`;

const generateStyles = ({ theme, menuSize }) => {
  return {
    control: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      height: '4rem',
      borderRadius: '0.8rem',
      fontSize: '1.3rem',
      borderColor: isFocused
        ? theme.colors.primary
        : tint(1 - 0.15, theme.colors.darkBlue),
      boxShadow: 'none',
      transition: 'all 0.25s ease-in-out',

      ':hover': {
        ...styles[':hover'],
        cursor: 'pointer',
        borderColor: isFocused
          ? theme.colors.primary
          : tint(1 - 0.15, theme.colors.darkBlue),
      },
    }),
    clearIndicator: styles => ({
      ...styles,
      paddingLeft: '0.4rem',
      paddingRight: '0',
    }),
    loadingIndicator: styles => ({
      ...styles,
      paddingLeft: '0.4rem',
      paddingRight: '0',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      paddingLeft: '0.4rem',
    }),
    indicatorSeparator: styles => ({
      display: 'none',
    }),
    placeholder: styles => ({
      ...styles,
      color: tint(1 - 0.25, theme.colors.darkBlue),
      fontSize: '1.3rem',
      fontStyle: 'italic',
    }),
    input: styles => ({
      ...styles,
      margin: 0,
      paddingLeft: '0.2rem',
    }),
    valueContainer: styles => ({
      ...styles,
      paddingLeft: '1.3rem',
      paddingRight: '1.5rem',
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      lineHeight: '1.6rem',
    }),
    menu: styles => ({
      ...styles,
      padding: `0`,
      border: `1px solid ${tint(1 - 0.1, theme.colors.darkBlue)}`,
      boxShadow: `0 2px 4px ${transparentize(1 - 0.05, theme.colors.darkBlue)}`,
      borderRadius: theme.radius.regular,
      overflow: 'hidden',
      minWidth: menuSize ? `${menuSize}px` : '100%',
    }),
    menuPortal: styles => ({ ...styles, zIndex: 9999 }),
    menuList: styles => ({
      ...styles,
      padding: `${theme.spacing.smaller}`,
    }),
    noOptionsMessage: styles => ({
      ...styles,
      fontSize: '1.3rem',
      color: tint(1 - 0.5, theme.colors.darkBlue),
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      marginBottom: theme.spacing.xsmall,
      borderRadius: theme.radius.small,
      fontSize: '1.3rem',
      lineHeight: '2rem',
      color: isSelected
        ? theme.colors.white
        : isFocused
        ? theme.colors.primary
        : tint(1 - 0.75, theme.colors.darkBlue),
      backgroundColor: isSelected
        ? theme.colors.primary
        : isFocused
        ? tint(1 - 0.05, theme.colors.primary)
        : theme.colors.white,
      transition: 'all 0.5s ease-in-out',

      ':hover': {
        ...styles[':hover'],
        cursor: 'pointer',
      },
      ':active': {
        ...styles[':active'],
        cursor: 'pointer',
        backgroundColor: isFocused
          ? tint(1 - 0.05, theme.colors.primary)
          : theme.colors.white,
      },
      ':last-child': {
        marginBottom: '0',
      },
    }),
  };
};

// Component
const SelectInput = props => {
  const [inputFocus, setInputFocus] = useState(false);
  const {
    required,
    value,
    onChange,
    theme,
    loadOptions,
    debounceTime,
    ...otherProps
  } = props;
  const debounceOptions = debounce(
    loadOptions,
    debounceTime ? debounceTime : 300,
    {
      leading: false,
    }
  );
  const { name, options } = props;
  return (
    <InputGroup>
      {props.error && (
        <Error type='span' color='red' tint={1}>
          {props.error}
        </Error>
      )}

      <InputContainer>
        {props.async ? (
          <Async
            id={name}
            {...otherProps}
            styles={generateStyles(props)}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            blurInputOnSelect={true}
            loadingMessage={() => 'Chargement'}
            onChange={event => {
              onChange({ target: { name, value: event ? event : null } });
            }}
            loadOptions={inputValue => debounceOptions(inputValue)}
          />
        ) : (
          <Select
            id={name}
            {...otherProps}
            styles={generateStyles(props)}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            blurInputOnSelect={true}
            value={options.find(({ value: i }) => i === value)}
            onChange={event => {
              onChange({ target: { name, value: event ? event.value : null } });
            }}
          />
        )}

        {!props.disabled && (
          // Waiting required props
          <input
            tabIndex={-1}
            autoComplete='off'
            style={{
              opacity: 0,
              width: 0,
              height: 0,
              padding: 0,
              margin: 0,
              position: 'absolute',
              left: '50%',
              zIndex: -1,
            }}
            value={value ? value : ''}
            onChange={() => null}
            required={required}
          />
        )}
        {props.icon && (
          <InputIcon iconRight={props.iconRight} onClick={props.iconAction}>
            {Icons[props.icon]({})}
          </InputIcon>
        )}
      </InputContainer>

      {(props.label || props.link) && (
        <InputLabel>
          <Label
            htmlFor={props.name}
            type='label'
            size='medium'
            weight='medium'
            tint={inputFocus ? 1 : 0.75}
            color={inputFocus ? 'primary' : ''}
          >
            {props.label} {props.required ? '*' : null}
          </Label>
          {props.link}
        </InputLabel>
      )}
    </InputGroup>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  icon: PropTypes.string,
  iconAction: PropTypes.func,
  onChange: PropTypes.func,
  link: PropTypes.element,
  menuSize: PropTypes.number,
};

SelectInput.defaultProps = {
  label: null,
  required: false,
  disabled: false,
  name: null,
  placeholder: null,
  value: null,
  icon: null,
  link: null,
  menuSize: null,
};

export default withTheme(SelectInput);
