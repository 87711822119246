import React from 'react';

const SvgAlertCircle = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='alert-circle_svg__feather alert-circle_svg__feather-alert-circle'
    {...props}
  >
    <circle cx={12} cy={12} r={10} />
    <path d='M12 8v4M12 16h0' />
  </svg>
);

export default SvgAlertCircle;
