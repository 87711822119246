import {
  DOCUMENT_ITEM_UNIT,
  DOCUMENT_STATUS,
  PAIDWITH,
} from '../constants/document.constants';

/**
 * Invoices status
 */
export const invoiceStatusOptions = [
  { value: DOCUMENT_STATUS.DRAFT, label: 'Brouillon' },
  { value: DOCUMENT_STATUS.SENT, label: 'Envoyée' },
  { value: DOCUMENT_STATUS.PAID, label: 'Payée' },
  { value: DOCUMENT_STATUS.LATE, label: 'En retard' },
];

/**
 * Estimates status
 */
export const estimateStatusOptions = [
  { value: DOCUMENT_STATUS.DRAFT, label: 'Brouillon' },
  { value: DOCUMENT_STATUS.SENT, label: 'Envoyé' },
  { value: DOCUMENT_STATUS.ACCEPTED, label: 'Accepté' },
  { value: DOCUMENT_STATUS.DECLINED, label: 'Décliné' },
];

/**
 * Invoices payableWithin
 */
export const payableWithinOptions = [
  { label: 'Dès reception', value: 0 },
  { label: '15 jours', value: 15 },
  { label: '30 jours', value: 30 },
  { label: '45 jours', value: 45 },
];

/**
 * Invoices paidWith
 */
export const paidWithOptions = [
  { value: PAIDWITH.BANK_TRANSFER, label: 'Virement bancaire' },
  { value: PAIDWITH.BANK_CHECK, label: 'Chèque' },
  { value: PAIDWITH.CASH, label: 'Espèces' },
  { value: PAIDWITH.CREDIT_CARD, label: 'Carte bancaire' },
];

/**
 * Document units
 */
export const documentUnitOptions = [
  { value: DOCUMENT_ITEM_UNIT.HOUR, label: 'heure' },
  { value: DOCUMENT_ITEM_UNIT.DAY, label: 'jour' },
  { value: DOCUMENT_ITEM_UNIT.SERVICE, label: 'prestation' },
  { value: DOCUMENT_ITEM_UNIT.PRODUCT, label: 'produit' },
  { value: DOCUMENT_ITEM_UNIT.WORD, label: 'mot' },
  { value: DOCUMENT_ITEM_UNIT.CHARACTER, label: 'caractère' },
  { value: DOCUMENT_ITEM_UNIT.ARTICLE, label: 'article' },
];

/**
 * Esitmates validity
 */
export const validityOptions = [
  { label: '15 jours', value: 15 },
  { label: '30 jours', value: 30 },
  { label: '45 jours', value: 45 },
  { label: '60 jours', value: 60 },
];

/**
 * VAT
 */
export const VATOptions = [
  { label: 'Pas de TVA', value: 0 },
  { label: '2,1%', value: 2.1 },
  { label: '5,5%', value: 5.5 },
  { label: '10%', value: 10 },
  { label: '20%', value: 20 },
];

export const VATNotApplicable = `TVA non applicable, article 293B du code général des impôts`;

export const defaultLegals = `Dispensé d'immatriculation au RCS et au répertoire des métiers.

En conformité de l’article L 441-6 du Code de commerce :
- La facture est payable selon le délai susmentionné.
- Pas d’escompte pour paiement anticipé.
- Tout règlement effectué après expiration du délai susmentionné donnera lieu, à titre de pénalité de retard, à l’application d’un intérêt égal à celui appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente, majoré de 10 points de pourcentage, ainsi qu'à une indemnité forfaitaire pour frais de recouvrement d'un montant de 40 Euros.
- Les pénalités de retard sont exigibles sans qu’un rappel soit nécessaire.

Informations concernant les droits d'exploitation :
- Le Client s’engage vis à vis du prestataire à disposer de tous les droits d’exploitation nécessaires à la réalisation des prestations objet du présent contrat.
- Le prestataire ne cède que les droits d’exploitation de la création limités aux termes du présent document.
- Le prestataire reste propriétaire de l’intégralité des créations tant que la prestation n’est pas entièrement réglée.
- Toute utilisation sortant du cadre initialement prévu dans cette facture est interdite; sauf autorisation expresse et écrite du prestataire.`;

export const estimateDefaultLegals = `Dispensé d'immatriculation au RCS et au répertoire des métiers.

- Le présent devis prévoit l’intégralité des prestations que le prestataire s’engage à réaliser pour le Client.
- Toute prestation supplémentaire demandée par le Client donnera lieu à l'émission d’un nouveau devis ou avenant.
- Une fois validé par le Client, le présent devis a valeur de contrat.
- Dans l’hypothèse d’une rupture de contrat à l’initiative du Client, ce dernier s’engage à régler les prestations réalisées.
- En cas d’acceptation du devis puis de dédit, complet ou partiel, du client, ce dernier devra régler une quote-part de 20% des sommes correspondant aux prestations non encore réalisées.

En conformité de l’article L 441-6 du Code de commerce :
- La facture correspondante sera payable selon le délai mentionné sur le document.
- Pas d’escompte pour paiement anticipé.
- Tout règlement effectué après expiration de ce délai donnera lieu, à titre de pénalité de retard, à l’application d’un intérêt égal à celui appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente, majoré de 10 points de pourcentage, ainsi qu'à une indemnité forfaitaire pour frais de recouvrement d'un montant de 40 Euros.
- Les pénalités de retard sont exigibles sans qu’un rappel soit nécessaire.

Informations concernant les droits d'exploitation :
- Le Client s’engage vis à vis du prestataire à disposer de tous les droits d’exploitation nécessaires à la réalisation des prestations objet du présent contrat.
- Le prestataire ne cède que les droits d’exploitation de la création limités aux termes du présent document.
- Le prestataire reste propriétaire de l’intégralité des créations tant que la prestation n’est pas entièrement réglée.
- Toute utilisation sortant du cadre initialement prévu dans ce devis est interdite; sauf autorisation expresse et écrite du prestataire.`;
