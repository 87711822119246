const ACCESS_TOKEN_STORAGE_KEY = 'atk';
const REFRESH_TOKEN_STORAGE_KEY = 'rtk';
const ISADMIN = 'admin';

const REFERRER = 'PK_referrer';

class StorageService {
  // STORE
  storeAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }
  storeRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  }
  storeIsAdmin(isAdmin) {
    localStorage.setItem(ISADMIN, isAdmin);
  }
  storeReferrer(referrer) {
    localStorage.setItem(REFERRER, JSON.stringify(referrer));
  }

  // GET
  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  }
  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  }
  getIsAdmin() {
    return localStorage.getItem(ISADMIN);
  }
  getReferrer() {
    return localStorage.getItem(REFERRER);
  }

  // CLEAR
  clearTokens() {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(ISADMIN);
  }
  clearReferrer() {
    localStorage.removeItem(REFERRER);
  }
  clearAllStorage() {
    localStorage.clear();
  }
}

export const storageService = new StorageService();
