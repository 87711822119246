import React from 'react';

const SvgSend = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='send_svg__feather send_svg__feather-send'
    {...props}
  >
    <path d='M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z' />
  </svg>
);

export default SvgSend;
