import React from 'react';

const SvgActivity = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='activity_svg__feather activity_svg__feather-activity'
    {...props}
  >
    <path d='M22 12h-4l-3 9L9 3l-3 9H2' />
  </svg>
);

export default SvgActivity;
