import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { tint, transparentize } from 'polished';

import { Text } from '../typography';
import { Wrapper } from '../utils';
import Icons from '../icons';

// Styled component
const CheckboxGroup = styled(props => <Wrapper {...props} />)`
  width: ${props => (props.full ? '100%' : 'auto')};
  min-height: ${props => (props.switch ? 'unset' : '4rem')};
  display: flex;
  align-items: center;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

// Label
const Label = styled(props => <Text {...props} />)`
  flex-grow: ${props => (props.full ? '1' : 'auto')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${props => (props.switch ? 'unset' : '4rem')};
  transition: all 0.25s ease-in-out;

  ${props =>
    props.bordered &&
    css`
      padding-left: ${props.theme.spacing.small};
      padding-right: ${props.theme.spacing.small};
    `}

  &:hover {
    cursor: pointer;
  }
`;

const CheckboxWrapper = styled(props => <Wrapper {...props} />)`
  position: relative;
  flex-shrink: 0;
`;

const HiddenCheckbox = styled.input.attrs(props => ({ type: props.type }))`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props =>
    props.switch ? (props.large ? '4.4rem' : '2.8rem') : '1.6rem'};
  height: ${props => (props.switch && props.large ? '2.4rem' : '1.6rem')};
  font-size: 1.4rem;
  border-radius: ${props =>
    props.switch || props.type === 'radio'
      ? props.large
        ? props.theme.radius.large
        : props.theme.radius.regular
      : props.theme.radius.small};
  color: ${props => props.theme.colors.white};
  background: ${props =>
    props.switch
      ? props.checked
        ? props.theme.colors[props.color]
        : tint(1 - 0.1, props.theme.colors.darkBlue)
      : props.checked
      ? props.theme.colors.primary
      : props.theme.colors.white};
  border: 1px solid
    ${props =>
      props.switch
        ? props.checked
          ? props.theme.colors[props.color]
          : tint(1 - 0.15, props.theme.colors.darkBlue)
        : props.checked
        ? props.theme.colors.primary
        : tint(1 - 0.15, props.theme.colors.darkBlue)};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;

const SwitchCursor = styled.div`
  position: absolute;
  width: ${({ large }) => (large ? '1.6rem' : '1rem')};
  height: ${({ large }) => (large ? '1.6rem' : '1rem')};
  top: 50%;
  left: ${({ checked, large }) =>
    checked ? (large ? '2.2rem' : '1.4rem') : large ? '0.4rem' : '0.2rem'};
  transform: translateY(-50%);
  border-radius: ${props => props.theme.radius.regular};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 4px
    ${props => transparentize(1 - 0.25, props.theme.colors.darkBlue)};
  transition: all 0.25s ease-in-out;
`;

const CheckboxText = styled(Text)`
  flex: 1;
  line-height: 1.6rem;
`;

// Component
const Checkbox = props => {
  return (
    <CheckboxGroup
      r={props.bordered ? 'regular' : null}
      color={props.bordered ? (props.checked ? 'primary' : null) : null}
      tint={props.bordered ? (props.checked ? 0.1 : null) : null}
      border={props.bordered}
      borderTint={props.bordered ? (props.checked ? 0.25 : 0.15) : null}
      borderColor={
        props.bordered ? (props.checked ? 'primary' : 'darkBlue') : null
      }
      bordered={props.bordered}
      full={props.full}
      switch={props.switch}
      disabled={props.disabled}
    >
      <Label
        htmlFor={props.id}
        type='label'
        full={props.full}
        switch={props.switch}
        bordered={props.bordered}
      >
        <CheckboxWrapper mr={props.label ? 'small' : null} mv='xsmall'>
          <HiddenCheckbox checked={props.checked} {...props} />
          <StyledCheckbox
            large={props.large}
            checked={props.checked}
            switch={props.switch}
            type={props.type}
            color={props.color}
          >
            {props.checked && !props.switch && <Icons.Check />}
            {props.switch && (
              <SwitchCursor checked={props.checked} large={props.large} />
            )}
          </StyledCheckbox>
        </CheckboxWrapper>
        {props.label && (
          <CheckboxText
            type='span'
            size='regular'
            weight='medium'
            tint={props.light ? 1 : 0.75}
            color={props.light ? 'white' : null}
          >
            {props.label}
          </CheckboxText>
        )}
      </Label>
    </CheckboxGroup>
  );
};

Checkbox.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  full: PropTypes.bool,
  switch: PropTypes.bool,
  color: PropTypes.string,
  light: PropTypes.bool,
  bordered: PropTypes.bool,
};

Checkbox.defaultProps = {
  type: 'checkbox',
  label: null,
  required: false,
  disabled: false,
  id: null,
  name: null,
  checked: false,
  switch: false,
  full: true,
  color: 'primary',
  light: false,
  bordered: false,
};

export default Checkbox;
