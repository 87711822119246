import React from 'react';

const SvgPWarning = props => (
  <svg width={32} height={32} fill='none' {...props}>
    <circle cx='17' cy='16' r='12' fill='#FCE1E6' />
    <circle
      cx='15'
      cy='16'
      r='11'
      fill='white'
      stroke='#EE3657'
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 10C14.4477 10 14 10.4477 14 11V17C14 17.5523 14.4477 18 15 18C15.5523 18 16 17.5523 16 17V11C16 10.4477 15.5523 10 15 10ZM15 22C15.5523 22 16 21.5523 16 21C16 20.4477 15.5523 20 15 20C14.4477 20 14 20.4477 14 21C14 21.5523 14.4477 22 15 22Z'
      fill='#EE3657'
    />
  </svg>
);

export default SvgPWarning;
