import { storageService } from './storage-service';
import { userService } from './user-service';
const axios = require('axios');

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 60000,
});

instance.interceptors.request.use(
  async function(config) {
    const accessToken = storageService.getAccessToken();
    if (accessToken && accessToken.length > 0) {
      const decodedToken = userService.getDecodedToken();
      const now = new Date().getTime();
      const isExpired = now >= decodedToken.expiresAt;
      if (isExpired && !config.url.includes('/account/refresh-token')) {
        const { token } = await userService.refreshToken();
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
        return config;
      } else {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };
        return config;
      }
    } else {
      return config;
    }
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error.response);
  }
);

export default instance;
