import React from 'react';

const SvgInfo = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='info_svg__feather info_svg__feather-info'
    {...props}
  >
    <circle cx={12} cy={12} r={10} />
    <path d='M12 16v-4M12 8h0' />
  </svg>
);

export default SvgInfo;
