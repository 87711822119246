import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { tint } from 'polished';

import { Text } from '../typography';
import { Wrapper } from '../utils';

// Styled component
const CheckboxGroup = styled.div`
  width: ${props => (props.full ? '100%' : 'auto')};
  min-height: 4rem;
  display: flex;
  align-items: center;
`;

// Label
const Label = styled(Text)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.25s ease-in-out;
`;

const CardWrapper = styled(props => <Wrapper {...props} />)`
  position: relative;
  flex-shrink: 0;
`;

const HiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCard = styled(props => <Wrapper {...props} />)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  border-radius: ${props =>
    props.large ? props.theme.radius.large : props.theme.radius.regular};
  color: ${props =>
    props.checked ? props.theme.colors.darkBlue : props.theme.colors.white};
  background: ${props => props.theme.colors.white};
  border: 1px solid
    ${props =>
      props.checked
        ? props.theme.colors.primary
        : tint(0.9, props.theme.colors.darkBlue)};
  box-shadow: ${props =>
    props.checked ? `0 1px 16px rgba(0,0,0, 0.08)` : 'none'};
  transition: all 150ms ease-in-out;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  &:hover {
    cursor: pointer;
  }
`;

// Component
const CheckboxCard = props => {
  return (
    <CheckboxGroup full={props.full}>
      <Label htmlFor={props.id} type='label' full={props.full}>
        <CardWrapper flex='1 0'>
          <HiddenInput
            type={props.type}
            id={props.id}
            checked={props.checked}
            {...props}
          />
          <StyledCard
            large={props.large}
            checked={props.checked}
            disabled={props.disabled}
            pl={props.large ? 'medium' : 'regular'}
            pr={props.large ? 'xxlarge' : 'regular'}
            pv={props.large ? 'medium' : 'xsmall'}
          >
            <Wrapper flex={'1 0'}>
              <Text
                type='p'
                size='regular'
                weight='medium'
                align='center'
                color={props.checked ? 'primary' : 'darkBlue'}
                tint={props.checked ? 1 : 0.75}
              >
                {props.label}
              </Text>

              {props.sublabel && (
                <Wrapper mt='regularSmall'>
                  <Text
                    type='p'
                    weight='semi'
                    size='small'
                    align='left'
                    color='primary'
                  >
                    {props.sublabel}
                  </Text>
                </Wrapper>
              )}
              {props.description && (
                <Wrapper mt='small'>
                  <Text weight='semi' size='small'>
                    {props.description}
                  </Text>
                </Wrapper>
              )}
            </Wrapper>
          </StyledCard>
        </CardWrapper>
      </Label>
    </CheckboxGroup>
  );
};

CheckboxCard.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  full: PropTypes.bool,
  large: PropTypes.bool,
};

CheckboxCard.defaultProps = {
  type: 'checkbox',
  label: null,
  sublabel: null,
  required: false,
  disabled: false,
  name: null,
  checked: false,
  full: false,
  large: false,
};

export default CheckboxCard;
