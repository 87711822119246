import React from 'react';

const SvgX = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='x_svg__feather x_svg__feather-x'
    {...props}
  >
    <path d='M18 6L6 18M6 6l12 12' />
  </svg>
);

export default SvgX;
