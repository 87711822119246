import httpService from './http-service'

const BASE_URL = '/document-item-template';

class DocumentItemTemplateService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  getDocumentItemTemplates({ limit = 20, skip = 0, title} = {}) {
    return httpService.get(this._generateRoute(''), {
      params: {
        limit,
        skip,
        title
      }
    });
  }

  getDocumentItemTemplatesCount({ limit = 20, skip = 0, title} = {}) {
    return httpService.get(this._generateRoute('count'), {
      params: {
        limit,
        skip,
        title
      }
    });
  }

  getDocumentItemTemplate(documentItemTemplateId) {
    return httpService.get(this._generateRoute(documentItemTemplateId))
  }

  createDocumentItemTemplate(documentItemTemplate) {
    return httpService.post(this._generateRoute('create'), documentItemTemplate);
  }

  updateDocumentItemTemplate(documentItemTemplateId, documentItemTemplate) {
    return httpService.put(this._generateRoute(documentItemTemplateId), documentItemTemplate);
  }

  deleteDocumentItemTemplate(documentItemTemplateId) {
    return httpService.delete(this._generateRoute(documentItemTemplateId));
  }

}

export const documentItemTemplateService = new DocumentItemTemplateService();
