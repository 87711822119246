import React from 'react';

const SvgFilter = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='filter_svg__feather filter_svg__feather-filter'
    {...props}
  >
    <path d='M22 3H2l8 9.46V19l4 2v-8.54L22 3z' />
  </svg>
);

export default SvgFilter;
