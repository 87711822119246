import Activity from './Activity';
import AlertCircle from './AlertCircle';
import AlertOctagon from './AlertOctagon';
import AlertTriangle from './AlertTriangle';
import Archive from './Archive';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import ArrowDown from './ArrowDown';
import ArrowUp from './ArrowUp';
import ArrowFull from './ArrowFull';
import Award from './Award';
import Calendar from './Calendar';
import CheckCircle from './CheckCircle';
import Check from './Check';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Clock from './Clock';
import Copy from './Copy';
import CreditCard from './CreditCard';
import Download from './Download';
import Edit from './Edit';
import Eye from './Eye';
import EyeOff from './EyeOff';
import ExternalLink from './ExternalLink';
import Facebook from './Facebook';
import FilePlus from './FilePlus';
import File from './File';
import FileText from './FileText';
import Filter from './Filter';
import Gift from './Gift';
import Heart from './Heart';
import HelpCircle from './HelpCircle';
import Image from './Image';
import Info from './Info';
import Layers from './Layers';
import LifeBuoy from './LifeBuoy';
import Link from './Link';
import Linkedin from './Linkedin';
import LogOut from './LogOut';
import Menu from './Menu';
import MessageCircle from './MessageCircle';
import Minus from './Minus';
import MoreHorizontal from './MoreHorizontal';
import Percent from './Percent';
import Play from './Play';
import Plus from './Plus';
import PlusSquare from './PlusSquare';
import Repeat from './Repeat';
import Search from './Search';
import Send from './Send';
import Settings from './Settings';
import Slash from './Slash';
import Sliders from './Sliders';
import Trash2 from './Trash2';
import Twitter from './Twitter';
import Upload from './Upload';
import UploadCloud from './UploadCloud';
import X from './X';
import XCircle from './XCircle';
import XOctagon from './XOctagon';
import Google from './Google';
import PInfo from './PInfo';
import PCustomer from './PCustomer';
import PProject from './PProject';
import PInvoice from './PInvoice';
import PCalendar from './PCalendar';
import PSpending from './PSpending';
import PQuote from './PQuote';
import PSettings from './PSettings';
import PDashboard from './PDashboard';
import PWarning from './PWarning';
import PCard from './PCard';
import PPlan from './PPlan';
import PItemTemplate from './PItemTemplate';

const Icons = {
  Activity,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  Archive,
  ArrowRight,
  ArrowLeft,
  ArrowFull,
  ArrowUp,
  ArrowDown,
  Award,
  Calendar,
  CheckCircle,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clock,
  Copy,
  CreditCard,
  Download,
  Edit,
  Eye,
  EyeOff,
  ExternalLink,
  Facebook,
  FilePlus,
  File,
  FileText,
  Filter,
  Gift,
  Heart,
  HelpCircle,
  Image,
  Info,
  Layers,
  LifeBuoy,
  Link,
  Linkedin,
  LogOut,
  Menu,
  MessageCircle,
  Minus,
  MoreHorizontal,
  Percent,
  Play,
  Plus,
  PlusSquare,
  Repeat,
  Search,
  Send,
  Settings,
  Slash,
  Sliders,
  Trash2,
  Twitter,
  Upload,
  UploadCloud,
  X,
  XCircle,
  XOctagon,
  Google,
  PInfo,
  PCustomer,
  PProject,
  PInvoice,
  PCalendar,
  PSpending,
  PQuote,
  PSettings,
  PDashboard,
  PWarning,
  PCard,
  PPlan,
  PItemTemplate,
};

export default Icons;
