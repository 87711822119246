import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { tint } from 'polished';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';

import { Text } from '../typography';
import Icons from '../icons';

// Styled component
const InputGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  color: ${props => props.theme.colors.darkBlue};

  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePickerInput {
    display: block;
    width: 100%;
    height: 4rem;
    border-radius: ${props => props.theme.radius.regular};
    border-color: ${props =>
      props.focused
        ? props.theme.colors.primary
        : tint(1 - 0.15, props.theme.colors.darkBlue)};
    overflow: hidden;
    transition: all 0.25s ease-in-out;
  }

  .DateInput {
    width: 100%;
  }

  .DateInput_input {
    width: 100%;
    height: 3.8rem;
    padding: 1.1rem 1.5rem;
    font-size: 1.3rem;
    font-weight: 400;
    border-bottom: 0;
    border-radius: ${props => props.theme.radius.regular};

    &:focus {
      outline: none;
    }

    ::placeholder {
      color: ${props => tint(1 - 0.25, props.theme.colors.darkBlue)};
      font-style: italic;
    }
  }
`;

// Icon
const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props =>
    props.iconRight
      ? css`
          right: 1.6rem;
        `
      : css`
          left: 1.6rem;
        `}

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

const InputLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`;

// Label
const Label = styled(Text)`
  flex-shrink: 0;
  transition: all 0.25s ease-in-out;
`;

// Error
const Error = styled(Text)`
  margin-top: 0.4rem;
`;

const SelectElement = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 0.4rem;
  height: 3.2rem;
  appearance: none;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${props => props.theme.colors.darkBlue};
  border: 1px solid ${props => tint(1 - 0.15, props.theme.colors.darkBlue)};
  border-radius: ${props => props.theme.radius.regular};
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

// Component
const DateInput = props => {
  const [inputFocus, setInputFocus] = useState(false);

  const returnYears = () => {
    let years = [];
    for (let i = moment().year() - 15; i <= moment().year(); i++) {
      years.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return years;
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <SelectElement
            value={month.month()}
            onChange={e => onMonthSelect(month, e.target.value)}
          >
            {moment.months().map((label, value) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </SelectElement>
        </div>
        <div>
          <SelectElement
            value={month.year()}
            onChange={e => onYearSelect(month, e.target.value)}
          >
            {returnYears()}
          </SelectElement>
        </div>
      </div>
    );
  };

  return (
    <InputGroup>
      {props.error && (
        <Error type='span' color='red' tint={1}>
          {props.error}
        </Error>
      )}
      <InputContainer focused={inputFocus}>
        <SingleDatePicker
          small
          placeholder={props.placeholder}
          required={props.required}
          appendToBody={true}
          firstDayOfWeek={1}
          displayFormat={moment.localeData().longDateFormat('L')}
          weekDayFormat={'dd'}
          renderMonthElement={renderMonthElement}
          date={props.value}
          focused={inputFocus}
          onFocusChange={({ focused }) => setInputFocus(focused)}
          id={props.name}
          numberOfMonths={1}
          onDateChange={date =>
            props.onChange({ target: { name: props.name, value: date } })
          }
          isOutsideRange={value => {
            if (props.block === 'after') {
              return value.isAfter(moment(), 'day');
            } else if (props.block === 'before') {
              return value.isBefore(moment(), 'day');
            } else if (!props.block) {
              return false;
            }
            // return value.isAfter(moment(), 'day');
          }}
        />
        {props.icon && (
          <InputIcon iconRight={props.iconRight} onClick={props.iconAction}>
            {Icons[props.icon]({})}
          </InputIcon>
        )}
      </InputContainer>
      <InputLabel>
        {props.label && (
          <Label
            htmlFor={props.name}
            type='label'
            size='medium'
            weight='medium'
            tint={inputFocus ? 1 : 0.75}
            color={inputFocus ? 'primary' : ''}
          >
            {props.label} {props.required ? '*' : null}
          </Label>
        )}
        {props.link}
      </InputLabel>
    </InputGroup>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  iconAction: PropTypes.func,
  onChange: PropTypes.func,
  link: PropTypes.element,
  block: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

DateInput.defaultProps = {
  label: null,
  required: false,
  disabled: false,
  name: null,
  placeholder: null,
  value: null,
  icon: null,
  iconRight: false,
  link: null,
  block: 'after'
};

export default DateInput;
