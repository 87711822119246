import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Text } from '../typography';
import { Wrapper } from '../utils';

const StyledText = styled(Text)``;

const StyledWrapper = styled(props =>
  props.type === 'button' ? <Wrapper {...props} /> : <NavLink {...props} />
)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom-width: ${({ large }) => (large ? '4px' : '1px')};
  border-bottom-style: solid;
  border-bottom-color: ${({ active, theme }) =>
    active ? `${theme.colors.primary}` : 'transparent'};
  transition: all 0.5s ease-in-out;

  ${StyledText} {
    opacity: ${({ active }) => (active ? 1 : 0.75)};
  }

  ${({ large }) =>
    large &&
    css`
      padding: ${({ theme }) => theme.spacing.regular};
    `}

  &.active {
    border-bottom-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    ${StyledText} {
      opacity: 1;
    }
  }
`;

const CountWrapper = styled(props => <Wrapper {...props} />)`
  min-width: 1.6rem;
`;

const Tab = ({
  children,
  active,
  type,
  large,
  light,
  count,
  ...otherProps
}) => {
  return (
    <StyledWrapper
      p='smaller'
      active={type === 'button' ? active : null}
      type={type}
      large={large ? 1 : 0}
      {...otherProps}
    >
      <StyledText
        type='span'
        size={large ? 'large' : 'regular'}
        weight='medium'
        color={active ? (light ? 'white' : 'primary') : light ? 'white' : null}
        tint={1}
        active={active}
        light={light}
      >
        {children}
      </StyledText>

      {count !== null && (
        <CountWrapper
          dInlineFlex
          justifyContent='center'
          alignItems='center'
          ml='smaller'
          ph='xsmall'
          r='small'
          color={active ? 'primary' : light ? 'white' : 'darkBlue'}
          transparentize={active ? (light ? 0.75 : 0.1) : light ? 0.15 : 0.05}
        >
          <Text
            type='span'
            weight='semi'
            size='xsmall'
            color={
              active
                ? light
                  ? 'white'
                  : 'primary'
                : light
                ? 'white'
                : 'darkBlue'
            }
            tint={active ? 1 : light ? 1 : 0.75}
          >
            {count}
          </Text>
        </CountWrapper>
      )}
    </StyledWrapper>
  );
};

export default Tab;

Tab.propTypes = {
  active: PropTypes.bool,
  type: PropTypes.string,
  large: PropTypes.bool,
  light: PropTypes.bool,
  count: PropTypes.number,
};

Tab.defaultProps = {
  active: false,
  type: 'button',
  large: false,
  light: false,
  count: null,
};
