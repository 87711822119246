import React from 'react';
import styled from 'styled-components';

import { Wrapper, Loader } from '../../../atoms/utils';
import { Logo } from '../../../atoms/logo';

const BrandedLoader = () => (
  <LoaderWrapper
    dFlex
    justifyContent={'center'}
    alignItems={'center'}
    color={'white'}
  >
    <Wrapper dFlex flexDirection='column'>
      <Loader mb='regular' color='darkBlue' tint={0.3} size='xsmall' />
      <Logo height='16px' tint={0.3} />
    </Wrapper>
  </LoaderWrapper>
);

export default BrandedLoader;

const LoaderWrapper = styled(Wrapper)`
  width: 100vw;
  height: 100%;
`;
