import { useState } from 'react';
import { DOCUMENT_TYPE } from '../utils/constants/document.constants';
import { invoiceService, estimateService } from '../services';

const useDocumentAction = () => {
  const [isProcessingAction, setProcessingAction] = useState(false);
  const [actionError, setActionError] = useState(null);

  const clean = () => {
    setProcessingAction(false);
    setActionError(null);
  };

  const doAction = (action = null) => {
    setProcessingAction(true);
    let actionPromise;

    if (action) {
      switch (action.type) {
        case 'mark-as-sent':
          if (action.documentType === DOCUMENT_TYPE.ESTIMATE) {
            actionPromise = estimateService.markAsSent(
              action.el,
              action.params
            );
          } else {
            actionPromise = invoiceService.markAsSent(action.el, action.params);
          }
          break;
        case 'mark-as-canceled':
          if (action.documentType === DOCUMENT_TYPE.ESTIMATE) {
            actionPromise = estimateService.markAsCanceled(action.el);
          } else {
            actionPromise = invoiceService.markAsCanceled(action.el);
          }
          break;
        case 'mark-as-paid':
          actionPromise = invoiceService.markAsPaid(action.el, action.params);
          break;
        case 'mark-as-accepted':
          actionPromise = estimateService.markAsAccepted(
            action.el,
            action.params
          );
          break;
        case 'mark-as-declined':
          actionPromise = estimateService.markAsDeclined(
            action.el,
            action.params
          );
          break;
        case 'delete':
          if (action.documentType === DOCUMENT_TYPE.ESTIMATE) {
            actionPromise = estimateService.deleteEstimateById(action.el);
          } else {
            actionPromise = invoiceService.deleteInvoiceById(action.el);
          }
          break;
        default:
          actionPromise = null;
      }

      if (actionPromise) {
        return actionPromise.then(
          res => {
            setActionError(null);
            setProcessingAction(false);
            return res;
          },
          err => {
            if (err && err.response && err.response.data) {
              if (err.response.data.code === 401) {
                setActionError('_revenue_limit_reached_');
              } else {
                setActionError(err.response.data.reason);
              }
            } else {
              setActionError('Erreur inconnue');
            }
            setProcessingAction(false);
            throw err;
          }
        );
      }
    }
  };

  return [{ isProcessingAction, actionError }, doAction, clean];
};

export default useDocumentAction;
