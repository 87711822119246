import React from 'react';

const SvgPCalendar = props => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      d='M5 10a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V10z'
      stroke='#F26881'
      strokeWidth={2}
    />
    <rect x={9} y={19} width={14} height={4} rx={2} fill='#FBCDD5' />
    <path
      d='M11 10a1 1 0 0 1-1-1V5a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1zM21 10a1 1 0 0 1-1-1V5a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1z'
      fill='#EE3657'
    />
  </svg>
);

export default SvgPCalendar;
