import React from 'react';
import PropTypes from 'prop-types';
import { tint } from 'polished';
import { theme } from '../../theme';

const Logo = props => (
  <svg
    width={props.width ? props.width : null}
    height={props.height ? props.height : null}
    viewBox='0 0 100 26'
    fill='none'
  >
    <path
      d='M0 25.7193H12.3715V24.386L9.30507 23.4737V15.8596L10.3625 16.1404C11.7018 16.5263 13.0059 16.7018 14.2396 16.7018C18.9626 16.7018 22.4872 13.9298 22.4872 9.12281C22.4872 4.2807 18.5749 1.57895 12.0543 1.57895H0V2.91228L2.89021 3.82456V23.4737L0 24.386V25.7193ZM9.30507 3.78947H11.5256C15.015 3.78947 15.9314 4.70175 15.9314 9.12281C15.9314 13.0877 15.3675 14.3509 12.3363 14.3509C11.9838 14.3509 11.5608 14.3509 11.1379 14.3158L9.30507 14.1754V3.78947Z'
      fill={props.color ? tint(1 - props.tint, theme.colors[props.color]) : tint(1 - props.tint, theme.colors.darkBlue)}
    />
    <path
      d='M27.0965 26C29.5286 26 31.5728 24.2105 32.595 22.4561C32.595 24.6667 34.0401 25.9298 36.3664 25.9298C38.0934 25.9298 39.2213 25.2982 40.1377 24.7719V23.3684C38.1992 23.193 37.882 23.0526 37.882 22V16.1053C37.882 9.96491 35.309 8.14035 30.5859 8.14035C27.449 8.14035 23.1842 9.4386 23.1842 12.6316C23.1842 14.2105 24.2063 15.3333 25.7219 15.3333C27.0965 15.3333 28.1187 14.386 28.1187 13.1579C28.1187 12.0702 27.5547 11.0526 26.1801 10.7719V10.7018C26.6736 10.5614 27.5195 10.4211 28.6826 10.4211C31.2909 10.4211 32.595 11.0175 32.595 13.5088V16.1053L29.599 16.6316C24.8055 17.4386 22.6555 18.9123 22.6555 21.9649C22.6555 24.4211 24.5588 26 27.0965 26ZM29.6343 22.9123C28.5769 22.9123 27.9425 22.4211 27.9425 21.0526C27.9425 19.5439 28.5064 18.807 30.3392 18.1404L32.595 17.3333V21.2632C31.7843 22.3158 30.7974 22.9123 29.6343 22.9123Z'
      fill={props.color ? tint(1 - props.tint, theme.colors[props.color]) : tint(1 - props.tint, theme.colors.darkBlue)}
    />
    <path
      d='M51.7644 25.7193H60.8932V23.7895H59.3776L53.4562 14.7018L57.721 10.6316H59.9768V8.70175H51.7291V10.6316H53.9849L48.134 17.7895V0H46.9004L40.1331 2.63158V3.40351L42.847 4.91228V23.7895H40.556V25.7193H49.6848V23.7895H48.134V19.7895L49.9668 18.0351L53.421 23.7895H51.7644V25.7193Z'
      fill={props.color ? tint(1 - props.tint, theme.colors[props.color]) : tint(1 - props.tint, theme.colors.darkBlue)}
    />
    <path
      d='M72.7677 25.7193H81.8965V23.7895H80.3809L74.4595 14.7018L78.7243 10.6316H80.9801V8.70175H72.7325V10.6316H74.9882L69.1373 17.7895V0H67.9037L61.1364 2.63158V3.40351L63.8503 4.91228V23.7895H61.5593V25.7193H70.6882V23.7895H69.1373V19.7895L70.9701 18.0351L74.4243 23.7895H72.7677V25.7193Z'
      fill={props.color ? tint(1 - props.tint, theme.colors[props.color]) : tint(1 - props.tint, theme.colors.darkBlue)}
    />
    <path
      d='M90.7302 26C96.1581 26 100 21.5789 100 17.0526C100 12.5263 96.1581 8.14035 90.7302 8.14035C85.3022 8.14035 81.4604 12.5263 81.4604 17.0526C81.4604 21.5789 85.3022 26 90.7302 26ZM90.7302 24.0351C88.6154 24.0351 87.3465 22.8772 87.3465 17.0526C87.3465 11.193 88.6506 10.1053 90.7302 10.1053C92.8097 10.1053 94.1138 11.1579 94.1138 17.0526C94.1138 22.8772 92.845 24.0351 90.7302 24.0351Z'
      fill={props.color ? tint(1 - props.tint, theme.colors[props.color]) : tint(1 - props.tint, theme.colors.darkBlue)}
    />
  </svg>
);

Logo.propTypes = {
  tint: PropTypes.number,
}

Logo.defaultProps = {
  tint: 1
}

export default Logo;
