import { transparentize } from 'polished';

let theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 3.2, // rem
    outerMargin: 1.6, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 80, // rem
      md: 96, // rem
      lg: 114, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 52.5, // em
      md: 64, // em
      lg: 75, // em
    },
  },
  colors: {
    white: '#FFFFFF',
    darkBlue: '#100A30',
    primary: '#624DE3',
    green: '#19C787',
    blue: '#0084FF',
    red: '#EE3657',
    yellow: '#FCB935',
    pink: '#EA64A4',
    twitter: '#1da1f2',
    facebook: '#1877f2',
    linkedin: '#0A66C2',
  },
  spacing: {
    xxsmall: '0.2rem',
    xsmall: '0.4rem',
    smaller: '0.8rem',
    small: '1.2rem',
    regular: '1.6rem',
    medium: '2.4rem',
    large: '3.2rem',
    xlarge: '4rem',
    xxlarge: '4.8rem',
    xxxlarge: '5.6rem',
    huge: '8rem',
    xhuge: '9.6rem',
    auto: 'auto',
  },
  radius: {
    circle: '9999px',
    large: '1.6rem',
    medium: '1.2rem',
    regular: '0.8rem',
    small: '0.4rem',
    xsmall: '0.2rem',
  },
};

theme = {
  ...theme,
  shadows: {
    regular: `0 0.2rem 0.4rem ${transparentize(
      1 - 0.05,
      theme.colors.darkBlue
    )}`,
    huge: `0 1.6rem 2.4rem ${transparentize(1 - 0.05, theme.colors.darkBlue)}`,
  },
};

export default theme;
