import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

import { BrandedLoader } from './components/commons/loaders';

const Maintenance = lazy(() => import('./routes/Maintenance'));
const Auth = lazy(() => import('./routes/Auth'));
const ConfirmAccount = lazy(() => import('./routes/ConfirmAccount'));
const Onboarding = lazy(() => import('./routes/Onboarding'));
const ConfirmEmail = lazy(() => import('./routes/ConfirmEmail'));
const Main = lazy(() => import('./routes/Main'));
const Documents = lazy(() => import('./routes/Documents'));
const Referral = lazy(() => import('./routes/Referral'));
const Import = lazy(() => import('./routes/Import'));
const LoginAs = lazy(() => import('./routes/LoginAs'));
const NotFound = lazy(() => import('./routes/NotFound'));

function App() {
  return (
    <Suspense fallback={<BrandedLoader />}>
      <Switch>
        {/* MAINTENANCE */}
        {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
          <>
            <Route path='/maintenance' component={Maintenance} />
            <Redirect to='/maintenance' />
          </>
        ) : (
          <Redirect exact from='/maintenance' to='/' />
        )}
        {/* NOT FOUND */}
        <Route path='/not-found' component={NotFound} />
        {/* NOT LOGGED */}
        <Route path='/auth' component={Auth} />
        <Route path='/pakkonnect/:token' component={LoginAs} />
        <Route
          path='/confirm-account/:confirmationId'
          component={ConfirmAccount}
        />
        <Route
          path='/confirm-email/:confirmationKey'
          component={ConfirmEmail}
        />
        <Route path='/documents' component={Documents} />
        <Route path='/referral' component={Referral} />
        {/* LOGGED */}
        <Route path='/onboarding' component={Onboarding} />
        <Route path='/import-invoices' component={Import} />
        <Route path='/' component={Main} />
      </Switch>
    </Suspense>
  );
}

export default withRouter(App);
