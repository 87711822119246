import React from 'react';

const SvgPInvoice = props => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      d='M1 8a3 3 0 0 1 3-3h17a3 3 0 0 1 3 3v15.056a5 5 0 0 0 .528 2.236L25.382 27H8a7 7 0 0 1-7-7V8z'
      stroke='#3EDEA3'
      strokeWidth={2}
    />
    <path
      d='M31 23.5a3.5 3.5 0 1 1-7 0V18h4.333A2.667 2.667 0 0 1 31 20.667V23.5z'
      fill='#42E9AD'
      fillOpacity={0.5}
      stroke='#3EDEA3'
      strokeWidth={2}
    />
    <path
      d='M13.118 16.864h-2.562c.252.448.598.798 1.036 1.05.448.252.957.378 1.526.378 1.046 0 1.792-.43 2.24-1.288h2.366c-.354.98-.947 1.74-1.778 2.282-.821.532-1.768.798-2.842.798-1.073 0-2.039-.29-2.898-.868a4.684 4.684 0 0 1-1.792-2.352H7.182v-1.26h.966a6.644 6.644 0 0 1-.028-.504c0-.121.01-.294.028-.518h-.966v-1.288h1.246a4.693 4.693 0 0 1 1.806-2.324 4.924 4.924 0 0 1 2.87-.882c1.074 0 2.021.27 2.842.812.831.541 1.424 1.302 1.778 2.282h-2.366c-.438-.859-1.213-1.288-2.324-1.288-1.101 0-1.927.467-2.478 1.4h2.562v1.288H10.15a6.682 6.682 0 0 0-.028.518c0 .112.01.28.028.504h2.968v1.26z'
      fill='#3EDEA3'
    />
  </svg>
);

export default SvgPInvoice;
