import { css } from 'styled-components';
import { rgba, tint } from 'polished';

import theme from './DefaultTheme';

const base = css`
  ::-moz-selection {
    /* Code for Firefox */
    color: ${theme.colors.primary};
    background: ${tint(0.95, theme.colors.primary)};
  }

  ::selection {
    color: ${theme.colors.primary};
    background: ${tint(0.95, theme.colors.primary)};
  }

  * {
    box-sizing: border-box;
    touch-action: pan-y pan-x;
  }

  html {
    height: 100%;
    font-size: 62.5%;
    -webkit-text-size-adjust: none;
  }

  *,
  body {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-size: 1.4rem;
    height: 100%;
    overflow: hidden;
    background-color: ${theme.colors.darkBlue};
  }

  body > .document_item {
    box-shadow: 0 8px 12px ${rgba(theme.colors.primary, 0.1)};
    border: 1px solid ${tint(0.2, theme.colors.primary)};
    border-radius: ${theme.radius.regular};
  }

  #root {
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  svg {
    display: block;
    font-size: inherit;
  }
`;

export default base;
