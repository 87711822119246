class UtilsBusinessService {
  //Same function is fact but lazy to find where the functions are used
  formatNumber(num, numberOfDigits = 2) {
    const result = num.toFixed(numberOfDigits);
    if (
      result.split('.')[1] ===
      Array(numberOfDigits)
        .fill('0')
        .join('')
    ) {
      return result.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } else {
      let parts = result.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join(',');
    }
  }

  formatNumberInput(number) {
    if (number >= 1) {
      return number.toString().replace(/^0+/, '');
    } else if (number < 1 && number >= 0) {
      return '0' + number.toString().replace(/^0+/, '');
    } else if (number < 0 && number > -1) {
      return '-0' + number.toString().replace(/^-0+/, '');
    } else {
      return number.toString().replace(/^0+/, '');
    }
  }

  capitalize(s) {
    return s.toString()[0].toUpperCase() + s.toString().slice(1);
  }

  getVATFromSiret(siret) {
    const formatedSiren = siret.replace(/\s/g, '').substring(0, 9);
    const VATKey = (12 + 3 * (parseInt(formatedSiren) % 97)) % 97;
    return `FR${VATKey < 10 ? `0${VATKey}` : VATKey}${formatedSiren}`;
  }

  checkEmailFormat(email) {
    const mailFormat = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!email.match(mailFormat) && email !== '') {
      return 'E-mail invalide';
    } else {
      return null;
    }
  }
}

export const utilsBusinessService = new UtilsBusinessService();
