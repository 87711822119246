import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { tint } from 'polished';

import { Wrapper } from '../utils';

const handleColor = props => {
  if (props.color) {
    if (props.tint) {
      return css`
        background-color: ${props =>
          tint(1 - props.tint, props.theme.colors[props.color])};
      `;
    } else {
      return css`
        background-color: ${props => props.theme.colors[props.color]};
      `;
    }
  }
};

const handleDotSize = props => {
  if (props.size === 'normal') {
    return css`
      width: 1.6rem;
      height: 1.6rem;
    `;
  }
  if (props.size === 'small') {
    return css`
      width: 0.8rem;
      height: 0.8rem;
    `;
  }

  if (props.size === 'xsmall') {
    return css`
      width: 0.6rem;
      height: 0.6rem;
    `;
  }
};

const handleWrapperSize = props => {
  if (props.size === 'normal') {
    return css`
      width: 7rem;
    `;
  }
  if (props.size === 'small') {
    return css`
      width: 4rem;
    `;
  }
  if (props.size === 'xsmall') {
    return css`
      width: 3.2rem;
    `;
  }
};

const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
    opacity: 0;
  } 40% { 
    transform: scale(1);
    opacity: 1;
  }
`;

const LoaderWrapper = styled(Wrapper)`
  ${handleWrapperSize};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const LoaderElement = styled.div`
  ${handleDotSize};
  ${handleColor};
  margin: 0 0.2rem;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
`;

const LoaderElement1 = styled(LoaderElement)`
  animation-delay: -0.32s;
`;
const LoaderElement2 = styled(LoaderElement)`
  animation-delay: -0.16s;
`;

// Component
const Loader = props => {
  const { color, tint, size, ...otherProps } = props;

  return (
    <LoaderWrapper size={size} {...otherProps}>
      <LoaderElement1 color={color} tint={tint} size={size} />
      <LoaderElement2 color={color} tint={tint} size={size} />
      <LoaderElement color={color} tint={tint} size={size} />
    </LoaderWrapper>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  tint: PropTypes.number,
  m: PropTypes.string,
  mh: PropTypes.string,
  mv: PropTypes.string,
  mt: PropTypes.string,
  mr: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
  p: PropTypes.string,
  ph: PropTypes.string,
  pv: PropTypes.string,
  pt: PropTypes.string,
  pr: PropTypes.string,
  pb: PropTypes.string,
  pl: PropTypes.string,
};

Loader.defaultProps = {
  size: 'small',
  color: 'darkBlue',
  tint: 0.75,
  m: null,
  mh: 'auto',
  mv: null,
  mt: null,
  mr: null,
  mb: null,
  ml: null,
  p: null,
  ph: null,
  pv: null,
  pt: null,
  pr: null,
  pb: null,
  pl: null,
};

export default Loader;
