export const DOCUMENT_TYPE = {
  ESTIMATE: 'estimate',
  INVOICE: 'invoice',
};

export const DOCUMENT_STATUS = {
  DRAFT: 'draft',
  SENT: 'sent',
  PAID: 'paid',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  INVOICED: 'invoiced',
  LATE: 'late',
  CANCELED: 'canceled'
};

export const INVOICE_FROM_ESTIMATE_METHODS = {
  DOWN_PAYMENT: 'downPayment',
  BALANCE: 'balance',
  FREE: 'free'
}

export const PAIDWITH = {
  BANK_TRANSFER: 'bankTransfer',
  BANK_CHECK: 'bankCheck',
  CASH: 'cash',
  CREDIT_CARD: 'creditCard',
};

export const DOCUMENT_ITEM_UNIT = {
  HOUR: 'hour',
  DAY: 'day',
  SERVICE: 'service',
  PRODUCT: 'product',
  WORD: 'word',
  CHARACTER: 'character',
  ARTICLE: 'article',
};
