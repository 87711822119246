import { css } from 'styled-components';
import { tint } from 'polished';

import theme from './DefaultTheme';

const calendar = css`
  .CalendarMonth_caption {
    padding-top: 18px !important;
  }

  .DayPickerNavigation_button__horizontalDefault {
    border-radius: 0.8rem !important;
    height: 3.2rem !important;

    &:focus {
      outline: none !important;
    }
  }

  .DayPicker_weekHeader_ul {
    font-size: 1.3rem !important;
  }

  .CalendarDay__hovered_span {
    color: black !important;
    &:hover {
      color: black !important;
    }
  }

  .CalendarDay {
    vertical-align: middle !important;
    border: none !important;
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    color: ${tint(1 - 0.75, theme.colors.darkBlue)} !important;
    transition: all 0.5s ease-in-out !important;

    &:focus {
      outline: none;
    }

    &:hover {
      background: ${tint(1 - 0.05, theme.colors.primary)} !important;
      border: none;
      color: ${theme.colors.primary} !important;
      border-radius: ${theme.radius.regular} !important;
    }
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${tint(1 - 0.25, theme.colors.darkBlue)} !important;
    background-color: ${theme.colors.white} !important;
  }

  .CalendarDay__today {
    color: ${theme.colors.primary} !important;
  }

  .CalendarDay__selected {
    background: ${theme.colors.primary} !important;
    border-radius: ${theme.radius.regular} !important;
    color: white !important;
    border: none !important;

    &.CalendarDay__today {
      color: ${theme.colors.white} !important;
    }

    &:hover {
      background: ${theme.colors.primary} !important;
      color: ${theme.colors.white} !important;
      border: none;
      border-radius: ${theme.radius.regular} !important;

      &.CalendarDay__today {
        color: ${theme.colors.white} !important;
      }
    }
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${tint(1 - 0.25, theme.colors.primary)} !important;
    border-radius: ${theme.radius.regular} !important;
    border: none;
  }

  .SingleDatePicker_picker {
    z-index: 9999 !important;
    border-radius: ${theme.radius.regular} !important;
  }

  .DayPicker__withBorder {
    border-radius: ${theme.radius.regular} !important;
    overflow: hidden;
  }

  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
`;

export default calendar;
