import PropTypes from 'prop-types';
import styled from 'styled-components';
import { tint } from 'polished';

import { Wrapper } from '../utils';

const Alert = styled(Wrapper)`
  background-color: ${props => tint(1 - 0.1, props.theme.colors[props.color])};
  border-radius: ${props => props.theme.radius.regular};
`;

Alert.propTypes = {
  color: PropTypes.string,
  ph: PropTypes.string,
  pv: PropTypes.string,
};

Alert.defaultProps = {
  color: 'darkBlue',
  ph: 'regular',
  pv: 'smaller',
};

export default Alert;
