import React from 'react';

const SvgXCircle = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='x-circle_svg__feather x-circle_svg__feather-x-circle'
    {...props}
  >
    <circle cx={12} cy={12} r={10} />
    <path d='M15 9l-6 6M9 9l6 6' />
  </svg>
);

export default SvgXCircle;
