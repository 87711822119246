import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { tint } from 'polished';

import { Wrapper } from '../utils';
import { Text } from '../typography';

// Styled component
const SeparatorWrapper = styled(Wrapper)`
  position: relative;
  min-height: 0.3rem;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 0.1rem;
    z-index: 0;
    ${props =>
      props.color
        ? css`
            background-color: ${tint(1 - 0.1, props.theme.colors[props.color])};
          `
        : css`
            background-color: ${tint(1 - 0.1, props.theme.colors.darkBlue)};
          `}
  }
`;

const SeparatorText = styled(Text)`
  position: relative;
  z-index: 1;
  padding: 0 0.8rem;
  background-color: #fff;
`;

// Component
const Separator = props => {
  return (
    <SeparatorWrapper {...props}>
      {props.text && (
        <SeparatorText
          type='span'
          uppercase
          weight='bold'
          size='xxsmall'
          color={props.color}
          tint={props.tint}
        >
          {props.text}
        </SeparatorText>
      )}
    </SeparatorWrapper>
  );
};

Separator.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  tint: PropTypes.number,
};

Separator.defaultProps = {
  text: null,
  color: null,
  tint: 0.25,
};

export default Separator;
