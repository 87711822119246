import React from 'react';
import PropTypes from 'prop-types';

import { utilsBusinessService } from '../../services/business';

const FormatNum = ({ number, prefix }) => {
  return (
    <>{`${prefix ? `${prefix} ` : ''}${utilsBusinessService.formatNumber(
      number
    )} €`}</>
  );
};

FormatNum.propTypes = {
  number: PropTypes.number,
};

FormatNum.defaultProps = {
  number: 0,
};

export default FormatNum;
