import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import base from './Base';
import calendar from './Calendar';

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${base}
  ${calendar}
`;

export default GlobalStyle;
