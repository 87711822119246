import React from 'react';

const SvgFileText = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='file-text_svg__feather file-text_svg__feather-file-text'
    {...props}
  >
    <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z' />
    <path d='M14 2v6h6M16 13H8M16 17H8M10 9H8' />
  </svg>
);

export default SvgFileText;
