import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize, tint } from 'polished';

// Size rules
const handleSize = props => {
  if (props.size === 'xxxlarge') {
    return css`
      font-size: 4rem;
      line-height: 4.4rem;
    `;
  }
  if (props.size === 'xxlarge') {
    return css`
      font-size: 3.2rem;
      line-height: 3.2rem;
    `;
  }
  if (props.size === 'xlarge') {
    return css`
      font-size: 2rem;
      line-height: 2rem;
    `;
  }
  if (props.size === 'large') {
    return css`
      font-size: 1.6rem;
      line-height: 2.4rem;
    `;
  }
  if (props.size === 'semi-regular') {
    return css`
      font-size: 1.4rem;
      line-height: 2rem;
    `;
  }
  if (props.size === 'regular') {
    return css`
      font-size: 1.3rem;
      line-height: 2rem;
    `;
  }
  if (props.size === 'medium') {
    return css`
      font-size: 1.2rem;
      line-height: 1.6rem;
    `;
  }
  if (props.size === 'small') {
    return css`
      font-size: 1.1rem;
      line-height: 1.6rem;
    `;
  }
  if (props.size === 'xsmall') {
    return css`
      font-size: 1rem;
      line-height: 1.6rem;
    `;
  }
  if (props.size === 'xxsmall') {
    return css`
      font-size: 0.9rem;
      line-height: 1.6rem;
    `;
  }
  if (props.size === 'xxxsmall') {
    return css`
      font-size: 0.8rem;
      line-height: 1.6rem;
    `;
  }
};

// Weight rules
const handleWeight = props => {
  if (props.weight === 'normal') {
    return css`
      font-weight: 400;
    `;
  }
  if (props.weight === 'medium') {
    return css`
      font-weight: 500;
    `;
  }
  if (props.weight === 'semi') {
    return css`
      font-weight: 600;
    `;
  }
  if (props.weight === 'bold') {
    return css`
      font-weight: 700;
    `;
  }
};

// Color rules
const handleColor = props => {
  if (props.opacity) {
    return css`
      color: ${props.color
        ? transparentize(1 - props.opacity, props.theme.colors[props.color])
        : transparentize(1 - props.opacity, props.theme.colors.darkBlue)};
    `;
  } else if (props.tint) {
    return css`
      color: ${props.color
        ? tint(1 - props.tint, props.theme.colors[props.color])
        : tint(1 - props.tint, props.theme.colors.darkBlue)};
    `;
  } else {
    return css`
      color: ${props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.darkBlue};
    `;
  }
};

// Styled component
const TextElement = styled.p`
  ${handleSize};
  ${handleWeight};
  ${handleColor};
  transition: all 0.25s ease-in-out;

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props =>
    props.uppercase
      ? css`
          text-transform: uppercase;
        `
      : null}

  ${props =>
    props.italic
      ? css`
          font-style: italic;
        `
      : null}

  ${props =>
    props.truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  
  ${props =>
    props.lineClamp &&
    css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: ${props.lineClamp};
    `}

  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  ${props =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `}

  ${props =>
    props.lineThrough &&
    css`
      text-decoration: line-through;
    `}

  ${props =>
    props.type === 'a' &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  strong {
    font-weight: 600;
  }
`;

// Component
const Text = props => {
  const { type, children } = props;

  return (
    <TextElement as={type.toString()} {...props}>
      {children}
    </TextElement>
  );
};

Text.propTypes = {
  type: PropTypes.oneOf(['a', 'p', 'span', 'label']),
  size: PropTypes.oneOf([
    'xxxlarge',
    'xxlarge',
    'xlarge',
    'large',
    'semi-regular',
    'regular',
    'medium',
    'small',
    'xsmall',
    'xxsmall',
    'xxxsmall',
  ]),
  weight: PropTypes.oneOf(['normal', 'medium', 'semi', 'bold']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.string,
  tint: PropTypes.number,
  opacity: PropTypes.number,
  uppercase: PropTypes.bool,
  italic: PropTypes.bool,
  truncate: PropTypes.bool,
  lineClamp: PropTypes.number,
  underline: PropTypes.bool,
  whiteSpace: PropTypes.string,
  lineThrough: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
};

Text.defaultProps = {
  type: 'p',
  size: 'regular',
  weight: 'normal',
  color: null,
  tint: 0.75,
  opacity: null,
  uppercase: false,
  italic: false,
  align: 'left',
  truncate: false,
  lineClamp: null,
  underline: false,
  whiteSpace: null,
  lineThrough: false,
  href: null,
  target: null,
};

export default Text;
