import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { storageService } from '../services/storage-service';

function useCRP() {
  const isAdmin = storageService.getIsAdmin();
  const hasIntercomId = process.env.REACT_APP_INTERCOM_ID;
  const { update, trackEvent, hardShutdown } = useIntercom();

  const _isUser = useCallback(() => {
    return !isAdmin || isAdmin !== 'true';
  }, [isAdmin]);

  const crpUpdate = useCallback(
    data => {
      if (_isUser() && hasIntercomId) {
        data ? update(data) : update();
      }
    },
    [update, _isUser, hasIntercomId]
  );

  const crpTrackEvent = useCallback(
    (event, data) => {
      if (_isUser() && hasIntercomId) {
        if (event) {
          data ? trackEvent(event, data) : trackEvent(event);
        }
      }
    },
    [trackEvent, _isUser, hasIntercomId]
  );

  const crpHardShutdown = useCallback(() => {
    if (_isUser() && hasIntercomId) {
      hardShutdown();
    }
  }, [hardShutdown, _isUser, hasIntercomId]);

  return { crpUpdate, crpTrackEvent, crpHardShutdown };
}

export default useCRP;
