import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components';

import { Text } from '../typography';

const TooltipElement = props => {
  const { children, content, isOpen } = props;

  return (
    <StyledTooltip
      arrowSize={6}
      distance={8}
      background={`#100A30`}
      padding={8}
      isOpen={isOpen}
      content={
        <Text
          weight='medium'
          size='small'
          color='white'
          align={'center'}
          tint={1}
        >
          {content}
        </Text>
      }
    >
      {children}
    </StyledTooltip>
  );
};

TooltipElement.propTypes = {
  content: PropTypes.string.isRequired,
};

TooltipElement.defaultProps = {
  content: 'Content',
};

export default TooltipElement;

const StyledTooltip = styled(Tooltip)`
  .react-tooltip-lite {
    max-width: 200px !important;
    border-radius: ${({ theme }) => theme.radius.regular};
    box-shadow: ${({ theme }) => theme.shadows.regular};
  }
`;
