export const USER_TYPE = {
  FREE: 'free',
  REGULAR: 'regular',
  TRIAL: 'trial',
  PAYMENT_ISSUES: 'payment_issues',
  PAID: 'paid',
};

export const LIMIT_TYPE = {
  REVENUE: 'revenue',
  CUSTOMER: 'customer',
};
