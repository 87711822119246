import GlobalStyle from './GlobalStyle';
import DefaultTheme from './DefaultTheme';
import CustomMediaQueries from './MediaQueries';

function getThemeFromProps(props) {
  return props.theme;
}

function getColors(props) {
  return getThemeFromProps(props).colors;
}

function getPrimaryColor(props) {
  return getColors(props).primary;
}

function getRedColor(props) {
  return getColors(props).red;
}

function getGreenColor(props) {
  return getColors(props).green;
}

function getDarkBlueColor(props) {
  return getColors(props).darkBlue;
}

function getWhiteColor(props) {
  return getColors(props).white;
}

function getRadius(props) {
  return getThemeFromProps(props).radius;
}
function getDefaultRadius(props) {
  return getRadius(props).regular;
}

export {
  DefaultTheme as theme,
  CustomMediaQueries as mediaQueries,
  getColors,
  getPrimaryColor,
  getGreenColor,
  getRedColor,
  getDarkBlueColor,
  getWhiteColor,
  getDefaultRadius,
  GlobalStyle,
};
