import React from 'react';

const SvgExternalLink = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='external-link_svg__feather external-link_svg__feather-external-link'
    {...props}
  >
    <path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14L21 3' />
  </svg>
);

export default SvgExternalLink;
