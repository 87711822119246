import { useState, useEffect, useCallback } from 'react';
import copy from "copy-to-clipboard";

function useCopyToClipboard(resetInterval = null) {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = useCallback((value) => {
    copy(value.toString());
    setCopySuccess(true);
  }, []);

  useEffect(() => {
    let timeout;
    if (copySuccess && resetInterval) {
      timeout = setTimeout(() => setCopySuccess(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [copySuccess, resetInterval]);

  return [copySuccess, handleCopy];
}

export default useCopyToClipboard;