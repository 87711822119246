import { useState } from 'react';
import fromEntries from 'object.fromentries';

function handleArgs(args) {
  if (!args) {
    console.error(
      'You should provide args to avoid React warnings from changing between uncontrolled to controlled input'
    );
    return {};
  }
  if (Array.isArray(args)) {
    const entries = args.map(k => {
      if (Array.isArray(k)) {
        return k;
      }
      return [k, ''];
    });
    return fromEntries(entries);
  }
  if (typeof args === 'object') {
    return args;
  }
}
function handleValidators(validators) {
  const entries = Object.keys(validators).map(e => [e, null]);
  return fromEntries(entries);
}

function useForm(keys, validators = {}) {
  const defaultState = handleArgs(keys);
  const [formValues, setFormValues] = useState(defaultState);
  const [errors, setErrors] = useState(handleValidators(validators));
  const handleChange = function(event) {
    if (event.persist) {
      event.persist();
    }
    const name = event.target.name;

    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setFormValues(inputs => {
      return {
        ...inputs,
        [name]: value,
      };
    });
    if (validators[name]) {
      setErrors(errors => {
        return {
          ...errors,
          [name]: validators[name](value),
        };
      });
    }
  };

  const resetErrors = () => {
    setErrors(handleValidators(validators));
  };

  return [formValues, handleChange, setFormValues, errors, resetErrors];
}

export default useForm;
