import React from 'react';

const SvgUpload = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='upload_svg__feather upload_svg__feather-upload'
    {...props}
  >
    <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M17 8l-5-5-5 5M12 3v12' />
  </svg>
);

export default SvgUpload;
