import httpService from './http-service';

const BASE_URL = '/deadline';

class DeadlineService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  getDeadlines() {
    return httpService.get(this._generateRoute(''));
  }

  markDeadlineAsDone(deadlineSlug) {
    return httpService.delete(this._generateRoute(deadlineSlug));
  }
}

export const deadlineService = new DeadlineService();