import { storageService } from './storage-service';
import httpService from './http-service';

const BASE_URL = '/subscription';

class ReferralService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  _storeReferrer(referrer) {
    storageService.storeReferrer(referrer);
  }

  _getReferrer() {
    const referrer = storageService.getReferrer();
    return referrer ? JSON.parse(referrer) : null;
  }

  _clearReferrer() {
    storageService.clearReferrer();
  }

  //
  // Manage Referral
  //
  getReferrerCode() {
    return httpService.get(this._generateRoute('referrer-code'));
  }

  getReferrerCodeInfos(referrerCode) {
    return httpService.get(
      this._generateRoute(`referrer-code/infos/${referrerCode}`)
    );
  }

  generateReferrerCode() {
    return httpService.post(this._generateRoute('referrer-code'));
  }

  getReferrerStats({ limit = 10, skip = 0 }) {
    return httpService.get(this._generateRoute('referrer-code/stats'), {
      params: {
        limit,
        skip,
      },
    });
  }

  getReferrerStatsCount() {
    return httpService.get(this._generateRoute('referrer-code/stats/count'));
  }

  getReferrerStatsAmount() {
    return httpService.get(
      this._generateRoute('referrer-code/stats/amount-saved')
    );
  }
}

export const referralService = new ReferralService();
