import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { tint } from 'polished';

import { useCopyToClipboard } from '../../hooks';

import { Wrapper } from '../utils';
import { Text } from '../typography';
import { Tooltip } from '../tooltip';
import Icons from '../icons';

const CopyButton = ({ fluid, label, weight, uppercase }) => {
  const [copySuccess, handleCopy] = useCopyToClipboard(1000);

  return (
    <CopyButtonElement fluid={fluid} onClick={() => handleCopy(label)}>
      <LabelWrapper flex='1 0'>
        <Text weight={weight} uppercase={uppercase} truncate>
          {label}
        </Text>
      </LabelWrapper>

      <Wrapper flex='0 0 auto' ml='regular'>
        <Tooltip
          content={copySuccess ? 'Copié !' : 'Copier'}
          isOpen={copySuccess}
        >
          {copySuccess ? <CopiedIcon /> : <CopyIcon />}
        </Tooltip>
      </Wrapper>
    </CopyButtonElement>
  );
};

const CopyButtonElement = styled('button')`
  max-width: 100%;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  outline: none;
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.regular}`};
  border: none;
  border-radius: ${({ theme }) => theme.radius.regular};
  background-color: ${({ theme }) => tint(0.95, theme.colors.primary)};
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => tint(0.93, theme.colors.primary)};
  }
`;

const LabelWrapper = styled(props => <Wrapper {...props} />)`
  min-width: 0;
`;

const CopyIcon = styled(Icons.Copy)`
  width: 1.6rem;
  height: 1.6rem;
  color: ${({ theme }) => tint(0.75, theme.colors.primary)};
  transition: all 0.25s ease-in-out;

  ${CopyButtonElement}:hover & {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const CopiedIcon = styled(Icons.Check)`
  width: 1.6rem;
  height: 1.6rem;
  color: ${({ theme }) => theme.colors.primary};
`;

CopyButton.propTypes = {
  fluid: PropTypes.bool,
  label: PropTypes.string,
  weight: PropTypes.string,
  uppercase: PropTypes.bool,
};

CopyButton.defaultProps = {
  fluid: false,
  label: 'Value',
  weight: 'semi',
  uppercase: false,
};

export default CopyButton;
