import httpService from './http-service';

const BASE_URL = '/project';

class ProjectService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  getProjects({ limit = 20, archived = false, skip = 0, search, customerId }) {
    return httpService.get(this._generateRoute(''), {
      params: {
        limit,
        archived,
        skip,
        search,
        customerId,
      },
    });
  }

  getProjectsCount({ archived = false, search, customerId } = {}) {
    return httpService.get(this._generateRoute('count'), {
      params: {
        archived,
        search,
        customerId,
      },
    });
  }

  getActiveProjects({limit = 10}) {
    return httpService.get(this._generateRoute('active'), {
      params: {
        limit
      }
    })
  }

  getProjectById(projectId) {
    return httpService.get(this._generateRoute(projectId));
  }

  createProject({ name, customerId }) {
    return httpService.post(this._generateRoute('create'), {
      name,
      customerId,
    });
  }

  createProjectsBulk(projects) {
    return httpService.post(this._generateRoute('create-bulk'), projects);
  }

  updateProject(projectId, { name, customerId }) {
    return httpService.put(this._generateRoute(projectId), {
      name,
      customerId,
    });
  }

  archiveProject(projectId) {
    return httpService.put(this._generateRoute(`${projectId}/archive`));
  }

  unarchiveProject(projectId) {
    return httpService.put(this._generateRoute(`${projectId}/unarchive`));
  }

  deleteProject(projectId) {
    return httpService.delete(this._generateRoute(projectId));
  }
}

export const projectService = new ProjectService();
