import { useCallback } from 'react';
import useMountedState from './useMountedState';

const useSafeAsync = () => {
  const isMounted = useMountedState();
  const safeAsync = useCallback(
    promise => {
      return new Promise((resolve, reject) => {
        promise
          .then(value => {
            if (isMounted()) {
              resolve(value);
            }
          })
          .catch(err => {
            if (isMounted()) {
              reject(err);
            }
          });
      });
    },
    [isMounted]
  );

  return safeAsync;
};

export default useSafeAsync;
