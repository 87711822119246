import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntercomProvider } from 'react-use-intercom';
import { hotjar } from 'react-hotjar';
import { CookiesProvider } from 'react-cookie';
//
import { theme, GlobalStyle } from '../src/theme';
import App from './App';
// DATES & MOMENT
import 'react-dates/initialize';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const Root = () => {
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'prod') {
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_ID,
        process.env.REACT_APP_HOTJAR_V
      );
    }
  }, []);

  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID} autoBoot>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </CookiesProvider>
    </IntercomProvider>
  );
};

export default Root;
