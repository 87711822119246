import moment from 'moment';

class SubscriptionBusinessService {
  //
  // SUBSCRIPTION
  //

  /**
   * Subscription is canceled
   *
   * @return { Boolean }
   */
  isCanceled(subscription) {
    return subscription.cancel_at_period_end;
  }

  /**
   * Get current plan data
   *
   * @return { String }
   */
  getCurrentProduct(subscription, products) {
    if (subscription) {
      const productId = subscription.items.data[0].plan.product;
      const product = products.find(
        el => el.product && el.product.id === productId
      );
      return product.product;
    } else {
      return null;
    }
  }

  /**
   * Get plan color
   *
   * @return { String }
   */
  getPlanColor(planId) {
    if (planId === 'pro') {
      return 'yellow';
    } else if (planId === 'basic') {
      return 'green';
    } else {
      return null;
    }
  }

  /**
   * Get Current Plan period
   *
   * @return { String }
   */
  getCurrentPlanPeriod(subscription) {
    const start = moment
      .unix(subscription.current_period_start)
      .format('DD	MMM YYYY');
    const end = moment
      .unix(subscription.current_period_end)
      .format('DD	MMM YYYY');

    return `Du ${start} au ${end}`;
  }

  /**
   * Get Current Plan interval
   *
   * @return { String }
   */
  getCurrentPlanInterval(subscription) {
    if (subscription.items.data[0].plan.interval === 'year') {
      return 'Annuel';
    } else if (subscription.items.data[0].plan.interval === 'month') {
      return 'Mensuel';
    }
  }

  /**
   * Get Current Plan period end
   *
   * @return { String }
   */
  getPlanCurrentPeriodEnd(subscription) {
    return moment.unix(subscription.current_period_end).format('DD	MMM YYYY');
  }

  /**
   * Get plan ending date after cancel
   *
   * @return { String }
   */
  getCanceledPlanEnding(subscription) {
    return moment.unix(subscription.cancel_at).format('DD MMM YYYY');
  }

  //
  // PLANS
  //

  /**
   * Filter prices by product
   *
   * @return { Object }
   */
  filterPricesByProduct(prices) {
    const plans = [
      {
        product: {
          active: true,
          metadata: {
            customers: '4',
            revenue: '5000',
            title: 'Gratuit',
            id: 'free',
            features:
              '["Suivi d\'activité & cotisations détaillées","Rappel des échéances à venir","Projets & devis illimités","Jusqu\'à 4 clients","Factures jusqu\'à 5 000 € de chiffre d\'affaires *"]',
          },
        },
        prices: [],
      },
    ];

    const pricesByProduct = prices.reduce((mem, p) => {
      return {
        ...mem,
        [p.product.id]: {
          ...mem[p.product.id],
          product: p.product,
          prices: mem[p.product.id] ? [...mem[p.product.id].prices, p] : [p],
        },
      };
    }, {});

    const productsArray = Object.keys(pricesByProduct)
      .map(productId => pricesByProduct[productId])
      .sort((a, b) => {
        if (a.product.metadata.id === 'basic') return -1;
        if (a.product.metadata.id !== 'basic') return 1;
        return 0;
      });

    return plans.concat(productsArray);
  }

  /**
   * Get plan price depending periodicity
   *
   * @return { String }
   */
  getPlanPriceId(plan, periodicity) {
    const price = plan.prices.find(el => el.recurring.interval === periodicity);
    return price.id;
  }

  /**
   * Get plan price depending periodicity
   *
   * @return { String }
   */
  getPlanPrice(plan, periodicity) {
    const price = plan.prices.find(el => el.recurring.interval === periodicity);
    return price.metadata.price_with_tax;
  }

  /**
   * Get plan subtitle price depending periodicity
   *
   * @return { String }
   */
  getPlanPriceSubtitle(plan, periodicity) {
    if (periodicity === 'year') {
      const price = plan.prices.find(el => el.recurring.interval === 'year');
      return price.metadata.initial_price;
    } else if (periodicity === 'month') {
      const price = plan.prices.find(el => el.recurring.interval === 'month');
      return price.metadata.annual_price;
    }
  }

  //
  // CARD
  //

  /**
   * Get card expiration date
   *
   * @return { String }
   */
  getCardExpirationDate(card) {
    const month = card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month;
    return `${month}/${card.exp_year}`;
  }

  /**
   * Get card formatted number
   *
   * @return { String }
   */
  getCardFormattedNumber(card) {
    return `**** **** **** ${card.last4}`;
  }

  //
  // INVOICES
  //

  /**
   * Get invoice date
   *
   * @return { String }
   */
  getFormattedInvoiceDate(invoice) {
    return moment.unix(invoice.created).format('DD/MM/YYYY');
  }

  /**
   * Get invoice amount
   *
   * @return { String }
   */
  getFormattedInvoiceAmount(invoice) {
    const price = (invoice.total / 100).toFixed(2).replace('.', ',');

    if (price.split(',')[1] === '00') {
      return `${price.split(',')[0]} €`;
    } else {
      return `${price} €`;
    }
  }

  //
  // PROMO CODE
  //
  getPromoCodeMessage(couponData) {
    if (couponData.metaData.description) {
      return couponData.metaData.description;
    } else {
      if (!couponData.currency) {
        return `Ce code de réduction vous offre une réduction de ${couponData.percentageDiscount}%`;
      } else {
        return `Ce code de réduction vous offre un crédit de ${couponData.priceDiscount /
          100} €`;
      }
    }
  }
}

export const subscriptionBusinessService = new SubscriptionBusinessService();
