import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    supportedLngs: ['fr', 'en'],
    preload: ['fr', 'en'],
    ns: [
      'commons',
      'auth',
      'account',
      'customer',
      'dashboard',
      'deadline',
      'document',
      'errors',
      'project',
    ],
    defaultNS: 'commons',
    debug: process.env.REACT_APP_NODE_ENV === 'dev',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/locales/add/{{lng}}/{{ns}}',
    },
  });

export default i18n;
