import React from 'react';

const SvgHelpCircle = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='none'
    stroke={props.color ? props.color : 'currentColor'}
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='help-circle_svg__feather help-circle_svg__feather-help-circle'
    {...props}
  >
    <circle cx={12} cy={12} r={10} />
    <path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3M12 17h0' />
  </svg>
);

export default SvgHelpCircle;
