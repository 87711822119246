import React from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import styled from 'styled-components';

import Icons from '../icons';

const SharerButton = ({ type, url }) => {
  return (
    <SharerButtonElement type={type.toLowerCase()} href={url} rel='noopener noreferrer' target='_blank'>
      <Icon type={type} />
    </SharerButtonElement>
  )
};

const SharerButtonElement = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  text-decoration: none;
  outline: none;
  background-color: ${({theme, type}) => theme.colors[type]};
  transition: all 0.25s ease-in-out;
  
  &:hover {
    background-color: ${({theme, type}) => darken(0.1,theme.colors[type])};
  }
`;

const Icon = styled(props => Icons[props.type]({...props}))`
  width: 1.6rem;
  height: 1.6rem;
  color: ${({theme}) => theme.colors.white};
`;

SharerButton.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
}

SharerButton.defaultProps = {
  type: 'Facebook',
  url: null,
}

export default SharerButton;