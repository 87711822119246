import React from 'react';

const SvgPProject = props => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <path
      opacity='0.5'
      d='M5.5 7.5V22.5L9.5 15L23.5 14.5L23 10L15.5 9.5L13 7L5.5 7.5Z'
      fill='#0084FF'
    />
    <path
      d='M24 15V12C24 10.895 23.105 10 22 10H16L13 7H7C5.895 7 5 7.895 5 9V26'
      stroke='#0084FF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5 26H23L26.91 17.393C27.306 16.206 26.344 15.004 24.998 15.004H10.477C9.599 15.004 8.824 15.533 8.566 16.307L5 26Z'
      stroke='#0084FF'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SvgPProject;
