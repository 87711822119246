import React from 'react';

const SvgPSpending = props => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      d='M25 27a3 3 0 0 0 3-3V12.105a3 3 0 0 0-3-3H6.319a5 5 0 0 1-2.048-.438L4 8.545V24a3 3 0 0 0 3 3h18z'
      stroke='#FCB935'
      strokeWidth={2}
    />
    <path
      d='M20 17a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-2z'
      stroke='#FCB935'
      strokeWidth={2}
    />
    <path
      d='M6 5a2 2 0 1 0 0 4h17V8a3 3 0 0 0-3-3H6z'
      fill='#FEEDCC'
      stroke='#FCB935'
      strokeWidth={2}
    />
  </svg>
);

export default SvgPSpending;
