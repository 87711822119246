import React from 'react';

const SvgPQuote = props => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      d='M1 8a3 3 0 0 1 3-3h17a3 3 0 0 1 3 3v15.789a5 5 0 0 0 .84 2.773l.291.438H8a7 7 0 0 1-7-7V8z'
      stroke='#624DE3'
      strokeWidth={2}
    />
    <path
      d='M31 23.2a3.8 3.8 0 0 1-3.8 3.8 3.2 3.2 0 0 1-3.2-3.2V18h4.333A2.667 2.667 0 0 1 31 20.667V23.2z'
      fill='#624DE3'
      fillOpacity={0.5}
      stroke='#624DE3'
      strokeWidth={2}
    />
    <path
      d='M8.278 17.416H9.91c.112-.688.528-1.024 1.072-1.024.944 0 1.552 1.04 2.88 1.04 1.424 0 2.496-.896 2.864-3.024h-1.664c-.096.704-.512 1.024-1.04 1.024-.928 0-1.568-1.04-2.896-1.04-1.424 0-2.48.88-2.848 3.024z'
      fill='#624DE3'
    />
  </svg>
);

export default SvgPQuote;
