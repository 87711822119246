import httpService from './http-service';

const BASE_URL = '/estimate';

class EstimateService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  getEstimates({ limit = 20, skip = 0, customerId, projectId, status } = {}) {
    return httpService.get(this._generateRoute(''), {
      params: {
        limit,
        skip,
        customerId,
        projectId,
        status,
      },
    });
  }

  getEstimatesCount({ customerId, projectId, status } = {}) {
    return httpService.get(this._generateRoute('count'), {
      params: {
        customerId,
        projectId,
        status,
      },
    });
  }

  getLastEstimateNumber() {
    return httpService.get(this._generateRoute('last-estimate-number'));
  }

  getEstimateById(estimateId) {
    return httpService.get(this._generateRoute(estimateId));
  }

  deleteEstimateById(estimateId) {
    return httpService.delete(this._generateRoute(estimateId));
  }

  markAsSent(estimateId, { date }) {
    return httpService.put(this._generateRoute(`${estimateId}/mark-as-sent`), {
      date,
    });
  }

  markAsAccepted(estimateId, { date }) {
    return httpService.put(
      this._generateRoute(`${estimateId}/mark-as-accepted`),
      {
        date,
      }
    );
  }

  markAsDeclined(estimateId, { date }) {
    return httpService.put(
      this._generateRoute(`${estimateId}/mark-as-declined`),
      {
        date,
      }
    );
  }

  markAsCanceled(estimateId) {
    return httpService.put(
      this._generateRoute(`${estimateId}/mark-as-canceled`)
    );
  }

  createEstimate(estimate) {
    return httpService.post(this._generateRoute('create'), estimate);
  }

  updateEstimate(estimateId, estimate) {
    return httpService.put(this._generateRoute(estimateId), estimate);
  }
}

export const estimateService = new EstimateService();
