import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from '../typography';
import { Button } from '../button';

const Label = styled(Text)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;

const HiddenInputFile = styled.input.attrs({ type: 'file' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const InputFileContainer = styled.div`
  display: inline-block;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

const ImportButton = styled(Button)`
  pointer-events: none;
`;

// Component
const FileSimple = props => {
  const { isUploading } = props;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isUploading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isUploading]);

  return (
    <InputFileContainer>
      <Label htmlFor={props.name} type='label'>
        <HiddenInputFile id={props.name} {...props} />
        <ImportButton outline isLoading={isLoading}>
          Importer un logo
        </ImportButton>
      </Label>
    </InputFileContainer>
  );
};

FileSimple.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  switch: PropTypes.bool,
};

FileSimple.defaultProps = {
  label: null,
  required: false,
  disabled: false,
  name: null,
  checked: false,
  switch: false,
};

export default FileSimple;
