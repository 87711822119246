import httpService from './http-service';

const BASE_URL = '/subscription';

class SubscriptionService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  //
  // Get available products
  //
  getProducts() {
    return httpService.get(this._generateRoute('products'));
  }

  //
  // Get available prices
  //
  getPrices() {
    return httpService.get(this._generateRoute('prices'));
  }

  //
  // Get taxes
  //
  getTaxes() {
    return httpService.get(this._generateRoute('taxes'));
  }

  //
  // Check coupon
  //
  checkCoupon(couponId) {
    return httpService.get(this._generateRoute(`coupon/${couponId}`));
  }

  //
  // Manage Card
  //
  getCardSetup() {
    return httpService.get(this._generateRoute('card/setup-intent'));
  }

  postCard(setupIntentId) {
    return httpService.post(this._generateRoute('card/setup-intent'), {
      id: setupIntentId,
    });
  }

  getCard() {
    return httpService.get(this._generateRoute('card'));
  }

  deleteCard() {
    return httpService.delete(this._generateRoute('card'));
  }

  //
  // Manage Subscription
  //
  getSubscription() {
    return httpService.get(this._generateRoute(''));
  }

  addSubscription(priceId, coupon) {
    return httpService.post(this._generateRoute('subscribe'), {
      priceId,
      coupon,
    });
  }

  updateSubscription(priceId, coupon) {
    return httpService.put(this._generateRoute('subscribe'), {
      priceId,
      coupon,
    });
  }

  cancelSubscription() {
    return httpService.delete(this._generateRoute(''));
  }

  //
  // Manage Invoices
  //
  getInvoices(limit = 5, startAfter = null, endBefore = null) {
    return httpService.get(this._generateRoute('invoices'), {
      params: {
        limit,
        startAfter,
        endBefore,
      },
    });
  }
}

export const subscriptionService = new SubscriptionService();
