import React from 'react';

const SvgPDashboard = props => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <path
      d='M4 5H21C22.6569 5 24 6.34315 24 8V23.7889C24 24.776 24.2922 25.7411 24.8397 26.5624L25.1315 27H8C4.13401 27 1 23.866 1 20V8C1 6.34315 2.34315 5 4 5Z'
      stroke='#624DE3'
      strokeWidth='2'
    />
    <path
      d='M24 23.8L24 18L28.3333 18C29.8061 18 31 19.1939 31 20.6667L31 23.2C31 25.2987 29.2987 27 27.2 27C25.4327 27 24 25.5673 24 23.8Z'
      fill='#624DE3'
      fillOpacity='0.5'
      stroke='#624DE3'
      strokeWidth='2'
    />
    <rect
      x='7'
      y='19'
      width='11'
      height='2'
      rx='1'
      fill='#624DE3'
      fillOpacity='0.5'
    />
    <path
      d='M9 18C8.44772 18 8 17.5523 8 17L8 16C8 15.4477 8.44772 15 9 15V15C9.55228 15 10 15.4477 10 16L10 17C10 17.5523 9.55228 18 9 18V18Z'
      fill='#624DE3'
      fillOpacity='0.7'
    />
    <path
      d='M13 18C12.4477 18 12 17.5523 12 17L12 14C12 13.4477 12.4477 13 13 13V13C13.5523 13 14 13.4477 14 14L14 17C14 17.5523 13.5523 18 13 18V18Z'
      fill='#624DE3'
      fillOpacity='0.8'
    />
    <path
      d='M17 18C16.4477 18 16 17.5523 16 17L16 12C16 11.4477 16.4477 11 17 11V11C17.5523 11 18 11.4477 18 12L18 17C18 17.5523 17.5523 18 17 18V18Z'
      fill='#624DE3'
    />
  </svg>
);

export default SvgPDashboard;
