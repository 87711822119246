class DataBusinessService {
  _enrichDataForDashboard({
    totalWithoutTax = 0,
    totalTax = 0,
    totalWithTax = 0,
    waitingForPayment = 0,
    waitingForPaymentWithTax = 0,
    fee = 0,
  } = {}) {
    return {
      totalWithTax: totalWithTax,
      totalWithoutTax: totalWithoutTax,
      totalTax: totalTax,
      waitingForPayment: waitingForPayment,
      waitingForPaymentWithTax: waitingForPaymentWithTax,
      fee,
      inMyPocket: totalWithoutTax - fee,
      totalFee: fee,
    };
  }

  // Months data -> Quarters data
  _monthsToQuarters(currentYearData, previousYearData) {
    let currentQuartersData = [];
    let previousQuartersData = [];

    for (let i = 0; i < 12; i = i + 3) {
      const currentYearQuarter = currentYearData.slice(i, i + 3).reduce(
        (acc, monthValue) => {
          return {
            totalWithTax: acc.totalWithTax + monthValue.totalWithTax,
            totalWithoutTax: acc.totalWithoutTax + monthValue.totalWithoutTax,
            totalTax: acc.totalTax + monthValue.totalTax,
            waitingForPayment:
              acc.waitingForPayment + monthValue.waitingForPayment,
            waitingForPaymentWithTax:
              acc.waitingForPaymentWithTax +
              monthValue.waitingForPaymentWithTax,
            fee: acc.fee + monthValue.fee,
            inMyPocket: acc.inMyPocket + monthValue.inMyPocket,
            totalFee: acc.totalFee + monthValue.totalFee,
          };
        },
        {
          totalWithTax: 0,
          totalWithoutTax: 0,
          totalTax: 0,
          waitingForPayment: 0,
          waitingForPaymentWithTax: 0,
          fee: 0,
          inMyPocket: 0,
          totalFee: 0,
        }
      );

      const previousYearQuarter = previousYearData.slice(i, i + 3).reduce(
        (acc, monthValue) => {
          return {
            totalWithTax: acc.totalWithTax + monthValue.totalWithTax,
            totalWithoutTax: acc.totalWithoutTax + monthValue.totalWithoutTax,
            totalTax: acc.totalTax + monthValue.totalTax,
            waitingForPayment:
              acc.waitingForPayment + monthValue.waitingForPayment,
            waitingForPaymentWithTax:
              acc.waitingForPaymentWithTax +
              monthValue.waitingForPaymentWithTax,
            fee: acc.fee + monthValue.fee,
            inMyPocket: acc.inMyPocket + monthValue.inMyPocket,
            totalFee: acc.totalFee + monthValue.totalFee,
          };
        },
        {
          totalWithTax: 0,
          totalWithoutTax: 0,
          totalTax: 0,
          waitingForPayment: 0,
          waitingForPaymentWithTax: 0,
          fee: 0,
          inMyPocket: 0,
          totalFee: 0,
        }
      );

      currentQuartersData.push(currentYearQuarter);
      previousQuartersData.push(previousYearQuarter);
    }

    return {
      currentYearData: currentQuartersData,
      previousYearData: previousQuartersData,
    };
  }

  getFormatedDataForDashboardData(rawData, year = 2019, period = 'months') {
    if (!rawData) {
      return {
        currentYearData: [],
        previousYearData: [],
      };
    }

    // Months
    const months = Array(12)
      .fill(0)
      .map((i, index) => index);
    // Current year
    const currentYearData = months.map(month => {
      if (rawData[year]) {
        const dataForMonth = rawData[year][month];
        if (dataForMonth) {
          return this._enrichDataForDashboard(dataForMonth);
        } else {
          return this._enrichDataForDashboard();
        }
      } else {
        return this._enrichDataForDashboard();
      }
    });
    // Previous Year
    const previousYearData = months.map(month => {
      if (rawData[year - 1]) {
        const dataForMonth = rawData[year - 1][month];
        if (dataForMonth) {
          return this._enrichDataForDashboard(dataForMonth);
        } else {
          return this._enrichDataForDashboard();
        }
      } else {
        return this._enrichDataForDashboard();
      }
    });

    if (period === 'months') {
      return {
        currentYearData,
        previousYearData,
      };
    } else if (period === 'quarters') {
      return this._monthsToQuarters(currentYearData, previousYearData);
    }
  }

  //
  // DATA FOR INVOICES CARD
  //
  getFormatedDataForInvoiceCardData({
    totalWithoutTax = 0,
    totalTax = 0,
    totalWithTax = 0,
    waitingForPayment = 0,
    waitingForPaymentWithTax = 0,
    fee = 0,
  } = {}) {
    return {
      totalWithTax: totalWithTax,
      totalWithoutTax: totalWithoutTax,
      totalTax: totalTax,
      waitingForPayment: waitingForPayment,
      waitingForPaymentWithTax: waitingForPaymentWithTax,
      fee,
      inMyPocket: totalWithoutTax.toFixed(0) - fee,
      totalFee: fee,
    };
  }

  //
  // DATA FOR CUSTOMER CARD
  //
  getFormatedDataForCustomerCardData({
    totalWithoutTax,
    totalWithTax,
    waitingForPayment,
    percentageOfTotal,
    fee,
  }) {
    return {
      totalWithTax: totalWithTax,
      totalWithoutTax: totalWithoutTax,
      waitingForPayment: waitingForPayment,
      fee,
      inMyPocket: totalWithoutTax.toFixed(0) - fee,
      percentageOfTotal: parseInt(percentageOfTotal),
    };
  }
}

export const dataBusinessService = new DataBusinessService();
