import { documentBusinessService } from './document-business-service';

class ProjectBusinessService {
  getWaitingPayments(project) {
    const { invoices } = project;
    if (!invoices) {
      console.error(
        `You must provide a project with the field invoices for project : ${project._id}`
      );
      return -1;
    }
    return invoices
      .filter(
        i =>
          documentBusinessService.isSent(i) &&
          !documentBusinessService.isCanceled(i) &&
          !documentBusinessService.isCredit(i)
      )
      .map(i => documentBusinessService.getTotalWithTax(i))
      .reduce((mem, current) => mem + current, 0);
  }

  // getRemainingPayments(project) {
  //   const { invoices } = project;
  //   if (!invoices) {
  //     console.error(
  //       `You must provide a project with the field invoices for project : ${project._id}`
  //     );
  //     return -1;
  //   }
  //   return invoices
  //     .filter(i => documentBusinessService.isDraft(i))
  //     .map(i => documentBusinessService.getTotalWithTax(i))
  //     .reduce((mem, current) => mem + current, 0);
  // }

  getRemainingPayments(project) {
    const invoicesByEstimate = this.getInvoicesByEstimate(project);

    return project.estimates
      .filter(
        e =>
          documentBusinessService.isAccepted(e) ||
          documentBusinessService.isInvoiced(e)
      )
      .reduce((mem, e) => {
        const estimateTotal = documentBusinessService.getTotalWithTax(e);

        if (invoicesByEstimate[e._id] && invoicesByEstimate[e._id].length > 0) {
          const totalPayments = invoicesByEstimate[e._id]
            .filter(
              i =>
                !documentBusinessService.isCredit(i) &&
                (documentBusinessService.isSent(i) ||
                  documentBusinessService.isPaid(i))
            )
            .map(i => documentBusinessService.getTotalWithTax(i))
            .reduce((mem, current) => mem + current, 0);

          return mem + (estimateTotal - totalPayments);
        } else {
          return mem + estimateTotal;
        }
      }, 0);
  }

  getInvoicesByEstimate(project) {
    return project.invoices.reduce(
      (mem, i) => {
        if (!i.estimateId) {
          return {
            ...mem,
            without_estimate: [...mem['without_estimate'], i],
          };
        }
        if (mem[i.estimateId]) {
          return {
            ...mem,
            [i.estimateId]: [...mem[i.estimateId], i],
          };
        } else {
          return {
            ...mem,
            [i.estimateId]: [i],
          };
        }
      },
      { without_estimate: [] }
    );
  }

  getInvoicesByEstimateId(project, estimateId) {
    const invoicesByEstimate = this.getInvoicesByEstimate(project);
    return invoicesByEstimate[estimateId] ? invoicesByEstimate[estimateId] : [];
  }
}

export const projectBusinessService = new ProjectBusinessService();
