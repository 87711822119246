import httpService from './http-service';

const BASE_URL = '/invoice';

class InvoiceService {
  _generateRoute(route) {
    return `${BASE_URL}/${route}`;
  }

  getInvoices({
    limit = 20,
    skip = 0,
    customerId,
    projectId,
    status,
    paidAtAfter,
    paidAtBefore,
  } = {}) {
    return httpService.get(this._generateRoute(''), {
      params: {
        limit,
        skip,
        customerId,
        projectId,
        status,
        paidAtAfter,
        paidAtBefore,
      },
    });
  }

  getInvoicesCount({
    customerId,
    projectId,
    status,
    paidAtAfter,
    paidAtBefore,
  } = {}) {
    return httpService.get(this._generateRoute('count'), {
      params: {
        customerId,
        projectId,
        status,
        paidAtAfter,
        paidAtBefore,
      },
    });
  }

  getLastInvoiceNumber() {
    return httpService.get(this._generateRoute('last-invoice-number'));
  }

  getInvoiceById(invoiceId) {
    return httpService.get(this._generateRoute(invoiceId));
  }

  deleteInvoiceById(invoiceId) {
    return httpService.delete(this._generateRoute(invoiceId));
  }

  markAsSent(invoiceId, { date }) {
    return httpService.put(this._generateRoute(`${invoiceId}/mark-as-sent`), {
      date,
    });
  }

  markAsPaid(invoiceId, { date, paidWith }) {
    return httpService.put(this._generateRoute(`${invoiceId}/mark-as-paid`), {
      date,
      paidWith,
    });
  }

  markAsCanceled(invoiceId) {
    return httpService.put(
      this._generateRoute(`${invoiceId}/mark-as-canceled`)
    );
  }

  /*{
    "projectId": "string",
    "number": "string",
    "status": "string",
    "customerInformation": "string",
    "dueAt": 0,
    "subject": "string",
    "discount': "number",
    "items": [
      {
        "title": "string",
        "description": "string",
        "quantity": 0,
        "price": 0
      }
    ],
    "companyInformation": "string",
    "bankInformation": "string",
    "legalNotice": "string",
    "VAT": 0,
    "external": true
  }*/

  createInvoice(invoice) {
    return httpService.post(this._generateRoute('create'), invoice);
  }

  createInvoicesBulk(invoices) {
    return httpService.post(this._generateRoute('create-bulk'), invoices);
  }

  updateInvoice(invoiceId, invoice) {
    return httpService.put(this._generateRoute(invoiceId), invoice);
  }
  getInvoiceCardDateForYear(year) {
    return httpService.get(this._generateRoute('data'), {
      params: {
        year,
      },
    });
  }
}

export const invoiceService = new InvoiceService();
