import React from 'react';

const SvgArrowFull = props => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M3.5439 9.92103C2.28062 8.36396 3.3586 6 5.33191 6H18.6681C20.6414 6 21.7194 8.36397 20.4561 9.92103L13.788 18.1398C12.8575 19.2867 11.1425 19.2867 10.212 18.1398L3.5439 9.92103Z'
      fill={props.color ? props.color : 'currentColor'}
    />
  </svg>
);

export default SvgArrowFull;
