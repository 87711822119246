import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const OpacityAppear = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const handleDuration = ({ duration }) => {
  if (duration) {
    return css`
      animation-duration: ${duration}s;
    `;
  }
};

const Animated = styled.div`
  animation-name: ${OpacityAppear};
  animation-timing-function: ease-in-out;
  ${handleDuration};

  ${({ h100 }) =>
    h100 &&
    css`
      height: 100%;
    `}
`;

export default Animated;

Animated.propTypes = {
  duration: PropTypes.number,
  h100: PropTypes.bool,
};

Animated.defaultProps = {
  duration: 0.75,
  h100: false,
};
